import React from 'react';

import TimeIsFickle from './time/TimeIsFickle';
import Music from './music/Music';
import Film from './film/Film';
import Imagery from './imagery/Imagery';
import Media from './media/Media';
import Artifact from './artifact/Artifact';
import Plan from './plan/Plan';
import Me from './me/Me';
import Welding from './welding/Welding';
import WordWall from './wordwall/WordWall';
import ModelOfYou from './model/ModelOfYou';
import Melange from './melange/Melange';
import Tremendous from './tremendous/Tremendous';
import LookingDown from './lookingdown/LookingDown';

import Hourglass from '../../assets/images/misc/hourglassturning.webp';
import Tumour from '../../assets/images/misc/pilocyticastrocytoma.jpg';
import MusicCover from '../../assets/images/music/nmesh/nmeshpathtolosteden.jpg';
import ImageryCover from '../../assets/images/imagery/Theo_van_Doesburg_Composition_VII_(the_three_graces).jpg';
import MediaCover from '../../assets/images/misc/media_title.png';
import FilmCover from '../../assets/images/misc/gattaca.jpg';
import MelangeCover from '../../assets/images/misc/duneblueyes.jpg'
import TheModelOfYouCover from '../../assets/images/misc/mirrorimagetwzone.png';
import PlanCover from '../../assets/images/misc/planning.jpg';
import CosmicProjection from '../../assets/images/misc/cosmicprojection.jpg';
import WeldingImage from '../../assets/images/misc/welding.png';
import Words from '../../assets/images/misc/words.jpg';
import TremendousImage from '../../assets/images/misc/adinfinum.jpg';
import LookingDownMountain from '../../assets/images/misc/lookingdown.jpg'



interface MiscItem {
    key: string;
    title: string;
    content: React.ReactNode;
    image: any;
}

const miscList: MiscItem[] = [
    {key: "time", title: "Time is Fickle", content: <TimeIsFickle/>, image: Hourglass},
    {key: "artifact", title: "Artifact", content: <Artifact/>, image: Tumour},
    {key: "music", title: "Music",content: <Music/>, image: MusicCover},
    {key: "imagery", title: "Imagery", content: <Imagery/>, image: ImageryCover},
    {key: "film", title: "Film",content: <Film/>, image: FilmCover},
    {key: "media", title: "Media",content: <Media/>, image: MediaCover},
    {key: "melange", title: "Melange", content: <Melange/>, image: MelangeCover},
    {key: "you", title: "The Model of You", content: <ModelOfYou/>, image: TheModelOfYouCover},
    {key: "plan", title: "The Plan", content: <Plan/>, image: PlanCover},
    {key: "me", title: "_me_", content: <Me/>, image: CosmicProjection},
    {key: "welding", title: "Welding", content: <Welding/>, image: WeldingImage},
    {key: "tremendous", title: "Tremendous", content: <Tremendous/>, image: TremendousImage},
    {key: "wordwall", title: "Word Wall", content: <WordWall/>, image: Words},
    {key: "lookingdown", title: "Looking Down", content: <LookingDown/>, image: LookingDownMountain},
]

export {miscList, MiscItem}