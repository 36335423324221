
import { Command } from './Command';
import { Arguments } from './Arguments';

import history from '../../../core/history';

export default class ListDirectory extends Command {
    usage: string = 'ls [string]';
    trigger: string = 'ls';
    
    execute(args: Arguments): string {
        let cwd = this.context.directory;

        // if(args.command.length !== 1) {
        //     return "[Error] " + this.trigger + " accepts 1 argument";
        // }
        console.log('CWD');
        console.log(cwd);
        let directoryString = cwd.children.map(dir => {
            return dir.path.substring(1,dir.path.length);
        }).join(' ');

        return directoryString;
    }

}