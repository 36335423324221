
import { Command } from './Command';
import { Arguments } from './Arguments';

import history from '../../../core/history';

function trimEnd (target: string, remove: string): string {
    while(target.charAt(target.length-1) === remove) {
        target = target.substring(0,target.length-1);
    }
    return target;
}

export default class ChangeDirectory extends Command {
    usage: string = 'cd [string]';
    trigger: string = 'cd';
    
    execute(args: Arguments): string {
        let cwd = this.context.directory;
        console.log(cwd);
        let input = args.command[0];
        console.log(input);

        if(input === '/') {
            this.context.directory = cwd.getRoot(cwd);
            history.push('/');
            return "";
        }

        input = trimEnd(input,'/'); 
        
        if(input.length === 0) {
            return "[Error] " + this.trigger + " accepts 1 argument";
        }

        if(input.startsWith('/')) {
            let target = this.context.directory.navigateAbsolute(input);
            if(target !== null) {
                this.context.directory = target;
                let current = this.context.directory.toString();
                history.push(current);
                return "";
            } else {
                return "[Error] No such file or directory";
            }
        }
        
        let target = this.context.directory.navigateRelative(input);
        if(target !== null) {
            this.context.directory = target;
            let current = this.context.directory.toString();
            history.push(current);
            return "";
        } else {
            return "[Error] No such file or directory";
        }

        return "";
    }

}