import * as Bowser from "bowser";
import { Command } from './Command';
import { Arguments } from './Arguments';

export default class UnixName extends Command {
    usage: string = 'uname';
    trigger: string = 'uname';
    
    execute(args: Arguments): string {
        let system = Bowser.parse(window.navigator.userAgent);
        let output = `${system.os.name} user ${system.browser.version} ${system.platform.type} ${system.browser.name} ${system.engine.name}`;
        return output;
    }

}