import React from 'react';
import {
    Route,
    Link,
    Switch,
    useRouteMatch
  } from "react-router-dom";
import styled from 'styled-components';

import {pageLoadAnimation} from '../../core/animations';

import {books, Book} from '../../content/books/books';

import BookView from './BookView';

import {device} from '../layouts/breakpoints'

const BooksWrapper = styled.div`
    ${pageLoadAnimation}
    text-align: center;
    height: 100%;
`;

const BookContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    
`;

const BookItem = styled.div`
    max-width: 160px;
    margin: 1rem;
    flex: 1 1 160px;
`;

const BookImage = styled.img`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    object-fit: contain;

`;

const BookTitle = styled.div`

`;

const Books: React.FC = () => {
    let { path, url } = useRouteMatch();
    let bookKeys = Object.keys(books);
    return (
        <BooksWrapper>
            <Switch>
            <Route exact path={path}>
                <h3>No guarantees given; comments and content criticism welcome.</h3>
                <h3>If you take interest in particular notes, read the book. The notes are not all-encompassing. Not everything captured is agreed with.</h3>
                <BookContainer>
                    {bookKeys.map((k) => {
                        let book: Book = books[k];
                        return (
                            <BookItem>
                                <Link to={`${url}/${k}`}>
                                    <BookImage src={book.image} width={128} height={128}/>
                                    <BookTitle>{book.title}</BookTitle>
                                </Link>
                            </BookItem>
                        )
                    })}
                </BookContainer>
            </Route>
            <Route path={`${path}/:title`}>
                <BookView/>
            </Route>
            </Switch>
        </BooksWrapper>
    );
}

export default Books;