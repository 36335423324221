import { Command } from './Command';
import { Arguments } from './Arguments';

export default class WhoAmI extends Command {
    usage: string = 'whoami';
    trigger: string = 'whoami';
    
    execute(args: Arguments): string {
        return 'user';
    }

}