import * as React from 'react';
import styled from 'styled-components';

const BlogContent = styled.div`
text-align: left;
padding: 1rem;
`

const Spacer = styled.p`
padding-bottom: 1em;
`

const BlogText = styled.div`
display: inline-block;
text-align: left;
font-size: 1.25em;
`
const P = styled.p`

`

const I = styled.span`
    font-style: italic;
`

const SongBlock = styled.pre`
white-space:pre-line;
font-style: oblique;
`;


export {BlogContent, Spacer, BlogText, P, I, SongBlock}