import * as React from 'react';
import styled from 'styled-components';

import Formal from '../../../assets/images/me/formal.jpg'
import Halloween from '../../../assets/images/me/halloween.jpg'
import Up from '../../../assets/images/me/UP.jpg'

import {
    Link,
    useRouteMatch,
  } from "react-router-dom";

const Top = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const MeContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0.75rem;
`

const MeItem = styled.div`
    max-width: 320px;
    flex: 1 1 320px;
    margin: 1rem;
`

const MeItemImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Me = () => {
    let { path, url } = useRouteMatch();

    return (
        <Top>
            <MeContainer>
                <MeItem>
                    <MeItemImage src={Formal}/>
                </MeItem>
                <MeItem>
                    <MeItemImage src={Halloween}/>
                </MeItem>
                <MeItem>
                    <MeItemImage src={Up}/>
                </MeItem>
                <MeItem>
                <Link to={`randomrandom`}>
                    Random
                </Link>
                </MeItem>

            </MeContainer>
        </Top>
    );
}

export default Me;