import { useRef, useEffect } from "react"
import React from "react";
import WordDrawable from './WordDrawable';
import ScrollingWord from "./ScrollingWord";
import { words, Word } from './words'
import styled from 'styled-components';
import { useState, State } from '@hookstate/core';
import Popup from 'reactjs-popup';
import { shuffle } from "../../core/util";

const WordBoardContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0.75rem;
    background: black;
`

const ModalContent = styled.div`
    background: white;
    border-style: solid;
`

const Test = styled.div`
    padding: 20px;
`

const WordBoard = () => {
    const state: State<WordDrawable[]> = useState([] as WordDrawable[]);
    const focusedState: State<boolean> = useState(false as boolean);
    const currentWordState: State<Word> = useState(null);

    const currentWord = currentWordState.get()
    const focusValue = focusedState.get()
    
    useEffect(() => {
        //fill the state
        const drawables = shuffle(words).slice(0,10).map((word: Word) => new WordDrawable(word));
        state.merge(drawables)
    }, [])

    const closeModal = () => {
        focusedState.set(false)
        currentWordState.set(null)
    }

    return (
        <WordBoardContainer>
            {state.map((drawableState: State<WordDrawable>, index) => 
                <ScrollingWord wordDrawableState={drawableState} focusedState={focusedState} currentWordState={currentWordState} key={index}/>
            )}
            <Popup open={focusValue} closeOnDocumentClick onClose={closeModal}>
                <ModalContent>
                    <Test>
                    {currentWord != null ? <>{currentWord.name} : {currentWord.definition}</> : <></>}
                    <a className="close" onClick={closeModal}>
                        &times;
                    </a>
                    </Test>
                </ModalContent>
            </Popup>
            
        </WordBoardContainer>
    )
}

export default WordBoard;