import FinalDays from "../../../assets/video/media/An Honest Death A Palliative Care Doctor's Final Days.mp4"
import Broke from "../../../assets/video/media/Broke 2009 Documentary on Canadian Pawn Shop A1 Trading.mp4"
import DEFCON from "../../../assets/video/media/DEFCON 19 - Steal Everything, Kill Everyone, Cause Total Financial Ruin.mp4"

interface Media {
    title: string;
    description: string;
    src: any;
}

const media: Media[] = [
    {
        title: "An Honest Death: A Palliative Care Doctor's Final Days",
        description: "Living isn't something we do alone, neither is dying. A 50 minute video that is worth at least 1 year of your life. Subtitled in English with narration. Audio cuts out from 11:00 - 15:59",
        src: FinalDays,
    },
    {
        title: "Broke 2009 Documentary on Canadian Pawn Shop A1 Trading",
        description: "An expose of a pawn shop and its characters.",
        src: Broke,
    },
    {
        title: "DEFCON 19 - Steal Everything, Kill Everyone, Cause Total Financial Ruin",
        description: '"I hate when they steal my stuff that I stole". This talk shows the amount of damage a malicious actor can create when motivated.',
        src: DEFCON,
    },
];


export {media, Media}