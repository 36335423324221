import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

interface PDFRendererProps {
    content: any;
}

const PDFRenderer: React.FC<PDFRendererProps> = (props) => {
    const url = window.location.origin + '/' + props.content

    return (
        <>
        <object data={url} style={{width: '95%', height: '80vh'}}>
            <p>Oops! Your browser doesn't support PDFs!</p>
            <p><a href={url}>Download</a></p>
        </object>
        </>
    );
}

export { PDFRenderer }