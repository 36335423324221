
interface Film {
    title: string;
    date: string;
    length: string;
}

const films: Film[] = [
    {title: `Gattaca`, date: `1997`, length: `1h 46min`},
    {title: `Chungking Express`, date: `1994`, length: `1h 42min`},
    {title: `Blade Runner`, date: `1982`, length: `1h 57min`},
    {title: `The Thing`, date: `1982`, length: `1h 49min`},
    {title: `American Psycho`, date: `2000`, length: `1h 41min`},
    {title: `Fallen Angels`, date: `1995`, length: `1h 39min`},
    {title: `THX 1138`, date: `1971`, length: `1h 26min`},
    {title: `Apocalypse Now`, date: `1979`, length: `1h 47min`},
    {title: `There Will Be Blood`, date: `2007`, length: `2h 38min`},
    {title: `The Twilight Zone (TV Series)`, date: `1959`, length: `Super Long`},
    {title: `The Outer Limits (TV Series)`, date: `1963`, length: `Super Long`},
    {title: `Pleasantville`, date: `1998`, length: `2h 4min`},
    {title: `Dune`, date: `2021`, length: `2h 36min`},
    {title: `In the Mood for Love`, date: `2000`, length: `1h 38min`},
    {title: `Devs (TV Series)`, date: `2020`, length: `6h 49min`},
    {title: `2046`, date: `2004`, length: `2h 9min`},
    {title: `Requiem for a Dream`, date: `2000`, length: `1h 42min`},
    {title: `Nightcrawler`, date: `2014`, length: `1h 17min`},
    {title: `Trainspotting`, date: `1996`, length: `1h 33min`},
    {title: `Westworld`, date: `1973`, length: `1h 28min`},
    {title: `Videodrome`, date: `1983`, length: `1h 27min`},
    {title: `The Platform`, date: `2019`, length: `1h 34min`},
    {title: `Scanners`, date: `1981`, length: `1h 43min`},
    {title: `eXistenZ`, date: `1999`, length: `1h 37min`},
    {title: `The Lobster`, date: `2015`, length: `1h 59min`},
    {title: `Logan's Run`, date: `1976`, length: `1h 59min`},
    {title: `Starship Troopers`, date: `1997`, length: `2h 9min`},
    {title: `Soylent Green`, date: `1973`, length: `1h 37min`},
    {title: `They Live`, date: `1988`, length: `1h 34min`},
    {title: `Invasion of the Body Snatchers`, date: `1978`, length: `1h 55min`},
    {title: `Alita: Battle Angel`, date: `2019`, length: `2h 2min`},
    {title: `The Blob`, date: `1988`, length: `1h 35min`},
    {title: `Society`, date: `1989`, length: `1h 39min`},
    {title: `Killer Klowns from Outer Space`, date: `1988`, length: `1h 28min`},
    {title: `Dark City`, date: `1998`, length: `1h 40min`},
    {title: `Donnie Darko`, date: `2001`, length: `1h 53min`},
    {title: `Idiocracy`, date: `2006`, length: `1h 24min`},
    {title: `Demolition Man`, date: `1993`, length: `1h 55min`},
    {title: `A Scanner Darkly`, date: `2006`, length: `1h 40min`},
    {title: `Annihilation`, date: `2018`, length: `1h 55min`},
    {title: `It Follows`, date: `2014`, length: `1h 40min`},
    {title: `Inception`, date: `2010`, length: `2h 28min`},
    {title: `Predestination`, date: `2014`, length: `1h 37min`},
    {title: `Snowpiercer`, date: `2013`, length: `2h 6min`},
    {title: `Minority Report`, date: `2002`, length: `2h 25min`},
    {title: `Blade Runner 2049`, date: `2017`, length: `2h 44min`},
    {title: `Total Recall`, date: `1990`, length: `1h 53min`},
    {title: `Brazil`, date: `1985`, length: `2h 12min`},
    {title: `District 9`, date: `2009`, length: `1h 52min`},
    {title: `RoboCop`, date: `1987`, length: `1h 42min`},
    {title: `The Running Man`, date: `1987`, length: `1h 41min`},
    {title: `Edge of Tomorrow`, date: `2014`, length: `1h 53min`},
    {title: `The Cabin in the Woods`, date: `2011`, length: `1h 35min`},
    {title: `Ex Machina`, date: `2014`, length: `1h 48min`},
    {title: `Léon: The Professional`, date: `1994`, length: `1h 50min`},
    {title: `Reservoir Dogs`, date: `1992`, length: `1h 39min`},
    {title: `Falling Down`, date: `1993`, length: `1h 53min`},
    {title: `Limitless`, date: `2011`, length: `1h 45min`},
    {title: `Tron: Legacy`, date: `2010`, length: `2h 5min`},
    {title: `Irreversible`, date: `2002`, length: `1h 37min`},
    {title: `The Matrix`, date: `1999`, length: `2h 16min`},
    {title: `Master and Commander: The Far Side of the World`, date: `2003`, length: `2h 18min`},
    {title: `Airplane!`, date: `1980`, length: `1h 28min`},
    {title: `Planes, Trains & Automobiles`, date: `1987`, length: `1h 33min`},
    {title: `The Black Hole`, date: `1979`, length: `1h 38min`},
    {title: `TRON`, date: `1982`, length: ` 1h 36min`},
    {title: `Uncle Buck`, date: `1989`, length: `1h 40min`},
    {title: `Ferris Bueller's Day Off`, date: `1986`, length: `1h 43min`},
    {title: `The Breakfast Club`, date: `1985`, length: `1h 37min`},
    {title: `Fast Times at Ridgemont High`, date: `1982`, length: `1h 30min`},
    {title: `Casablanca`, date: `1942`, length: `1h 42min`},
    {title: `The Truman Show`, date: `1998`, length: `1h 43min`},
    {title: `The Departed`, date: `2006`, length: `2h 31min`},
    {title: `No Country for Old Men`, date: `2007`, length: ` 2h 2min`},
    {title: `Elysium`, date: `2013`, length: `1h 49min`},
    {title: `Let the Right One In`, date: `2008`, length: `1h 54min`},
    {title: `Pulp Fiction`, date: `1994`, length: `2h 34min`},
    {title: `Citizen Kane`, date: `1941`, length: `1h 59min`},
    {title: `1917`, date: `2019`, length: `1h 59min`},
    {title: `Spirited Away`, date: `2001`, length: `2h 5min`},
    {title: `Dont Look Up`, date: `2021`, length: `2h 18min`},
    {title: `The General`, date: `1926`, length: `1h 47min`},
    {title: `The Shining`, date: `1980`, length: `2h 26min`},
    {title: `Band of Brothers (TV Series)`, date: `2001`, length: `9h 54min`},
    {title: `Fantastic Planet`, date: `1973`, length: `1h 12min`},
    {title: `Eraserhead`, date: `1977`, length: `1h 29min`},
    {title: `Silent Hill`, date: `2006`, length: `2h 5min`},
    {title: `Coraline`, date: `2009`, length: `1h 40min`},
    {title: `Dune`, date: `1984`, length: `2h 15min`},
    {title: `Breaking Bad (TV Series)`, date: `2008`, length: `Long`},
    {title: `Interstella 5555: The 5tory of the 5ecret 5tar 5ystem`, date: `2003`, length: `1h 5min`},
    {title: `Better Call Saul (TV Series)`, date: `2015`, length: `Long`},
    {title: `Arrested Development (TV Series)`, date: `2003`, length: `Long`},
    {title: `Porco Rosso`, date: `1992`, length: `1h 34min`},
    {title: `Berserk (TV Series)`, date: `1997`, length: `Long`},
    {title: `One Punch Man (TV Series)`, date: `2015`, length: `Long`},
    {title: `Attack on Titan (TV Series)`, date: `2013`, length: `Long`},
    {title: `Samurai Jack (TV Series)`, date: `2001`, length: `Long`},
    {title: `Archer (TV Series)`, date: `2009`, length: `Super Long`},
    {title: `The Ren & Stimpy Show (TV Series)`, date: `1991`, length: `Long`},
    {title: `Æon Flux (TV Series)`, date: `1991`, length: `Moderate`},
    {title: `Avatar: The Last Airbender (TV Series)`, date: `2005`, length: `Long`},
    {title: `Dexter's Laboratory (TV Series)`, date: `1996`, length: `Long`},
    {title: `ReBoot (TV Series)`, date: `1994`, length: `Long`},
];

export {Film, films}