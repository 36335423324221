import * as React from 'react';
import {useState} from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import colors from '../../core/colors';
import Title from './Title';

import CollapseMenu from './CollapseMenu';
import BurgerMenu from './BurgerMenu';

const FlexContainer = styled.div`
    max-width: 120rem;
    display: flex;
    margin: auto;
    padding: 0 2rem;
    justify-content: space-between;
    height: 5rem;
`;

const NavLinks = styled.ul`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    color: ${colors.primary};
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }

    @media (max-width: 768px) {
        display: none;
    }
  }
`;

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

//icon or name
//link elements

const NavBar: React.FC = () => {
  const [navbar, setNavbar] = useState<boolean>();

  const updateNav = () => {
    console.log("UPDATE_NAV_CALLED")
    setNavbar(!navbar);
  }

    return (
        <FlexContainer>
            <Title>MaxGDN</Title>
            <NavLinks>
                <Link to={'/'}>Home</Link>
                <Link to={'/about'}>About</Link>
                <Link to={'/misc'}>Misc</Link>
                <Link to={'/books'}>Books</Link>
                <Link to={'/projects'}>Projects</Link>
                
            </NavLinks>
            <BurgerWrapper>
            <BurgerMenu
              navbarState={navbar} 
              handleNavbar={updateNav}
            />
            </BurgerWrapper>
            <CollapseMenu 
              navbarState={navbar} 
              handleNavbar={updateNav}
            />
          </FlexContainer>
    );
}

export default NavBar;