import { Command } from './Command';
import { Arguments } from './Arguments';

export default class Echo extends Command {
    usage: string = 'echo [string]';
    trigger: string = 'echo';
    
    execute(args: Arguments): string {
        return args.command.join(' ');
    }

}