import * as React from 'react';
import styled from 'styled-components';
import RandomText from './RandomText';
import { F, B } from './SpinText';

const SpecialBanner = () => {
    return (
        <>
        <RandomText>⁂</RandomText> <F>Ⓦ</F><B>Ⓔ</B><F>Ⓛ</F><B>Ⓒ</B><F>Ⓞ</F><B>Ⓜ</B><F>Ⓔ</F> to MaxGDN <RandomText>⁂</RandomText>
        </>
    );
}

export default SpecialBanner;