import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import rainbow from './images/rainbowtriangle.png';
import code from './images/codetriangle.png';


console.log(rainbow); 
console.log(code); 

const Image = styled.img`
    max-width: 100%;
    height: auto;
`;

const Wrapper = styled.div`
    max-width: 100%;
    max-height: 80%;
    text-align: center;
`;

const TriangleImage: React.FC = (props) => {
    const imageRef = useRef(null);
    const [visible, setVisible] = useState(false);

    const codeImage = () => {
        if(imageRef.current) {
            imageRef.current.src = code;
        }
    }

    const rainbowImage = () => {
        if(imageRef.current) {
            imageRef.current.src = rainbow;
        }
    }

    const wrapperOnClick = (state: boolean) => {
        setVisible(state)
        if(state == false) {
            rainbowImage()
        } else {
            codeImage()
        }      
    }

    return (
        <Wrapper onClick={() => wrapperOnClick(!visible)}>
            <Image src={rainbow} ref={imageRef} onMouseOver={() => wrapperOnClick(true)} onMouseOut={() => wrapperOnClick(false)}/>
        </Wrapper>
        
    );
}

export default TriangleImage;