import * as React from 'react';
import styled from 'styled-components';

import DefaultMusic from '../../../assets/images/music/defaultsongicon.jpg'
import {artistList, Album, Song, Artist} from './music';
import LazyLoad from 'react-lazyload';

const Container = styled.div`
    
`

const ArtistContainer = styled.div`
`

const ArtistTitle = styled.h1`
    
`

const AlbumContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.75rem;
`

const AlbumTitle = styled.h3`
`

const SongItem = styled.div`
    max-width: 150px;
    flex: 1 1 150px;
    margin: 1rem;
    
    &:hover {
        cursor: pointer;
    }
`

const SongClickable = styled.a`
    text-decoration: none;
    color: inherit;

    &:hover, &:focus, &:active {
        text-decoration: none;
        color: inherit;
    }
`

const SongItemTitle = styled.div`
    overflow-wrap: break-word;
`

const SongItemImage = styled.img`
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
`

const Music = () => {
    return (
        <Container>
        <p>Grouped by artist as they are the generators of each piece of work. Order is organic.</p>
        {artistList.map((artist: Artist) => {
            let albums = artist.albums;
            return (
                <ArtistContainer>
                <ArtistTitle>{artist.name}</ArtistTitle>
            
                    {albums.map((album:Album) => {
                         let songs = album.songs

                         return (
                             <>
                                <LazyLoad>
                                    <AlbumTitle>{album.name}</AlbumTitle>
                                    <AlbumContainer>
                                        {songs.map((song: Song) => {
                                            let image = album.image;
                                            
                                            if(album.image == null) {
                                                if(song.image == null) {
                                                    image = DefaultMusic
                                                } else {
                                                    image = song.image
                                                }
                                            } 

                                            return (
                                                <SongItem>
                                                    <SongClickable href={song.links[0]} target="_blank">
                                                        <div>
                                                            <SongItemImage src={image}/>
                                                        </div>
                                                        <SongItemTitle>
                                                            {song.title}
                                                        </SongItemTitle>
                                                    </SongClickable>
                                                </SongItem>
                                            );
                                        })}
                                    </AlbumContainer>
                                </LazyLoad>
                            </>
                         )
                    })}
                </ArtistContainer>
            );
        })}
        </Container>
    );
}

export default Music;