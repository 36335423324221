import * as React from 'react';
import styled from 'styled-components';

import {
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";

import Home from '../pages/Home';
import About from '../pages/About';
import Projects from '../pages/Projects';
import Books from '../pages/Books';
import Misc from '../pages/Misc';
import CatchAll from '../pages/CatchAll';

import ErrorBoundary from '../generic/ErrorBoundary';

import Terminal from '../Terminal/Terminal';
import TerminalBar from '../generic/TerminalBar';

const ContentWrapper = styled.main`
    flex: 1;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
`;

const Center = styled.div`
    width: 100%;
    height: 100%;
`;

const Content: React.FC = () => {
    return (
        <ContentWrapper>
            <ErrorBoundary>
                <TerminalBar/>
                <Center>
                    <Switch>
                        <Route exact path={'/'} component={Home}/>
                        <Route exact path={'/about'} component={About}/>
                        <Route exact path={'/projects'} component={Projects}/>
                        <Route path={'/books'} component={Books}/>
                        <Route path={'/misc'} component={Misc}/>
                        <Route component={CatchAll}/>
                    </Switch>
                </Center>
            </ErrorBoundary>
        </ContentWrapper>
    );
}

export default Content;