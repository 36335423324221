import React from 'react';
import styled from 'styled-components';

import BlueEyes from '../../../assets/images/misc/duneblueyes.jpg'

import Card from '../../../components/generic/Card';
import { BlogContent, BlogText, Spacer, P, I } from '../../../components/blog/utils';

const Background = styled.div`
display: inline-block;
    opacity: 0.9;
    background: #fff url(${BlueEyes}) no-repeat center;
    background-size: contain;
    min-height: 250px;
    min-width: 250px;
    margin: 0 auto;
`

const SmallText = styled.p`
    font-size: 0.75rem
`

const Melange = () => {
    return (
        <Card>
            <BlogContent>
                <BlogText>
                    <h2>Melange</h2>
                    <Spacer/>
                    <Background/>
                    <SmallText>Paul Atreides, portrayed by Kyle MacLachlan in Lynch's Dune</SmallText>
                    <Spacer/>
                    <P>The Spice Melange is an awareness spectrum narcotic [expands an individual's consciousness] from the science fiction series Dune.</P>
                    <Spacer/>
                    <P>The whimsical technology, objects, and phenomena in science fiction can often seem far away from implementation and usage.</P>
                    <Spacer/>
                    <I>The Science of Dune: An Unauthorized Exploration into the Real Science Behind Frank Herbert's Fictional Universe</I> draws parallels between the Spice Melange and Psychedelics [yanked from pages 6-7].
                    <Spacer/>
                    <P>• <I>Telltale changes to the eye.</I> Hallucinogens such as LSD and ayahuasca produce extreme dilation of the pupil; from their daily large doses of the spice, the Fremen and the Guild had a deep dark blue coloring to the sclera.</P>
                    <Spacer/>
                    <P>• <I>Suspension of time.</I> Seconds become hours, or time stops altogether. Paul was unconscious for three weeks when he made his experiment with changing the Water of Life, but believed it had only been a few minutes.</P>
                    <Spacer/>
                    <P>• <I>Ecstatic (or sometimes frightening) sense of communion with others.</I> Depending on cultural context, this mystic communion might be with ancestral spirits (the Other Memory of the Bene Gesserit), or gods, or with the universe self.</P>
                    <Spacer/>
                    <P>• <I>Out of body sensations.</I> Jessica "felt that she was a conscious mote, smaller than any subatomic particle" (Dune 354)</P>
                    <Spacer/>
                    <P>• <I>Loss of self and merger into a oneness.</I> Jessica experienced something she called a "pychokinesthetic extension" of self (Dune 354)</P>
                    <Spacer/>
                    <P>• <I>Euphoria.</I> A major component of the experience with terrestrial hallucinogens, euphoria was part of Jessica's experience of the Water of Life. "The muzziness of the drug was overpowering her senses. How warm it was and soothing. How beneficent of these Freman to bring her into the fold of such companionship" (Dune 359).</P>
                    <Spacer/>
                    <P>• <I>Death-rebirth experience.</I> This is Hofmann's characterization of the progression from physical agony or terror  and to euphoria that is characteristic of LSD and related hallucinogens. The spice agony that a Bene Gesserit Reverend Mother had to undergo seemed to follow a similar course from pain (the spice essence could be fatal) to awakening to a shared communion with her predecessors.</P>
                    <Spacer/>
                    <P>• <I>Visions/hallucinations.</I> Phantasmagoric shifts to color and shape are typical of LSD, but the experience may bring a sense of traveling through space or time to visualize ancient cities or strange civilizations. Sometimes a voice comments upon or interprets the hallucinogenic experience. It seems that the Other Memory of the Bene Gesserit was not the product of their discipline so much as it was the gift of the spice. At  the moment of this death, planetologist Liet-Kynes, who had the eyes of a melange user, had his own experiences with Other Memory (his hallucination of his father).</P>
                    <Spacer/>
                    <P>• <I>Prescience and life-changing realizations.</I> For the Amazonion shamans, ayahuasca allows the soul to leave the body to search out the explanation for illness in the individual or problems threating the community and to decide the course of action. When Paul tasted the unchanged Water of Life, he saw the ships of the Guild filling the space above Arrakis and understood why they were there―that they, too, were addicted to the spice―and knew what he needed to do to reclaim Arrakis for House Atreides</P>
                    <Spacer/>
                    <P>
                        After investigation, Melange is not so far from what has existed on earth for thousands of years.
                    </P>
                    <Spacer/>
                    <P>
                        <I>“He who controls the spice controls the universe"</I> ― David Lynch's adaptation of Frank Herbert's, Dune
                    </P>
                    <Spacer/>
                    <a href="https://youtu.be/S3VMfzoBO7Y" target="_blank">Watch Dune</a>
                </BlogText>
            </BlogContent>
        </Card>
    );
}

export default Melange;