import React from 'react';
import styled from 'styled-components';

import Arnolfini from '../../../assets/images/imagery/arnolfini.jpg';
import DanteMeditating from '../../../assets/images/imagery/Joseph_Noel_Paton_Dante_Meditating_the_Episode_of_Francesca_da_Rimini_and_Paolo_Malatesta.jpg';
import ParadisoCanto from '../../../assets/images/imagery/Paradiso_Canto_31.jpg';
import DrawingHands from '../../../assets/images/imagery/DrawingHands.jpg';
import IchiroTsuruta from '../../../assets/images/imagery/IchiroTsuruta.jpg';
import ChristGivingHisBlessing from '../../../assets/images/imagery/Christ_Giving_His_Blessing.jpg';
import Voorvaders from '../../../assets/images/imagery/Voorvaders.jpg';
import DeDrieBomen from '../../../assets/images/imagery/Dedriebomen.jpg';
import MijnPantoffles from '../../../assets/images/imagery/MijnPantoffles.jpg';
import ChunkingExpress from '../../../assets/images/imagery/chungkingexpress.jpeg';
import PraiseTheCroc from '../../../assets/images/imagery/Praise_the_Cook_1999.jpg'
import ChangeOfHeart from '../../../assets/images/imagery/changeofheart.jpg';
import TheAllegoryofFaith from '../../../assets/images/imagery/The_Allegory_of_Faith.jpg';
import DunningKruger from '../../../assets/images/imagery/dunningkruger.png';
import Sisyphus from '../../../assets/images/imagery/Sisyphus.jpg';
import TheNinthWave from '../../../assets/images/imagery/TheNinthWave.jpg';
import TheThreeGraces from '../../../assets/images/imagery/Theo_van_Doesburg_Composition_VII_(the_three_graces).jpg';
import CardPlayers from '../../../assets/images/imagery/Theo_van_Doesburg_-_Card_players.jpg';
import MagnificentHummingBird from '../../../assets/images/imagery/magnificenthummingbird.jpg';

import {
    Top,
    ImageContainer,
    ImageItem,
    ImageItemImage,
    ImageItemTitle,
    ItemTypeA
} from '../../../components/blog/image';

const ZhItem = styled.div`
    font-size: 2rem;
`;

const Spacer = styled.div`
    margin-top: 2rem;
`

const Imagery = () => {
    
    return (
        <Top>
            <ImageContainer>
                <ItemTypeA image={Arnolfini} title={'Arnolfini Portrait'}/>
                <ItemTypeA image={DanteMeditating} title={'Dante Meditating the Episode of Francesca da Rimini and Paolo Malatesta'}/>
                <ImageItem>
                    <Spacer/>
                    <ZhItem>One</ZhItem>
                    <ZhItem>一 二 三</ZhItem>
                    <ZhItem>Wood</ZhItem>
                    <ZhItem>木 林 森</ZhItem>
                    <ZhItem>Fire</ZhItem>
                    <ZhItem>火 炎 焱</ZhItem>
                    <ZhItem>Person</ZhItem>
                    <ZhItem>人 从 众</ZhItem>
                    <ImageItemTitle>The beauty of self composition</ImageItemTitle>
                </ImageItem>
                <ItemTypeA image={ParadisoCanto} title={'Paradiso'}/>
                <ItemTypeA image={DrawingHands} title={'Drawing Hands'}/>
                <ItemTypeA image={IchiroTsuruta} title={'Ichiro Tsuruta'}/>
                <ItemTypeA image={ChristGivingHisBlessing} title={'Christ Giving His Blessing'}/>
                <ItemTypeA image={Voorvaders} title={'Voorvaders'}/>
                <ItemTypeA image={DeDrieBomen} title={'De Drie Bomen - Rembrant'}/>
                <ItemTypeA image={MijnPantoffles} title={'Mijn Pantoffles - Jopie Huisman'}/>
                <ItemTypeA image={ChunkingExpress} title={'重慶森林'}/>
                <ItemTypeA image={PraiseTheCroc} title={'Crocodiles - Rae Mate'}/>
                <ItemTypeA image={ChangeOfHeart} title={'Change of Heart - Yugioh'}/>
                <ItemTypeA image={TheAllegoryofFaith} title={'The Allegory of Faith - Vermeer'}/>
                <ItemTypeA image={DunningKruger} title={'Dunning Kruger'}/>
                <ItemTypeA image={Sisyphus} title={'Sisyphus'}/>
                <ItemTypeA image={TheNinthWave} title={'The Ninth Wave'}/>
                <ItemTypeA image={TheThreeGraces} title={'Composition VII (The Three Graces)'}/>
                <ItemTypeA image={CardPlayers} title={'Card Players'}/>
                <ItemTypeA image={MagnificentHummingBird} title={'Magnificent Hummingbird'}/>
            </ImageContainer>
        </Top>
    )
}

export default Imagery;