import { Command } from "./Command";
import Directory from './Directory';

export default class CommandContext {
    public commands: Command[];
    public directory: Directory;

    setCommands(commands: Command[]) {
        this.commands = commands;
    }

    setDirectory(directory: Directory) {
        this.directory = directory;
    }
}