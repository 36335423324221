import React, {useEffect} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom'
import {miscList} from '../../content/misc/misc';
import { useHistory } from "react-router-dom";

const BackButton = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: #0000EE;
`

const MiscView = () => {
    let { misc } = useParams();
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log(misc)
    let selected = miscList.filter(item => item.key === misc) [0]
    let history = useHistory();
    
    const goToMisc = () => {
        history.push('/misc')
    }

    return (
        <>
        <BackButton onClick={goToMisc}>Back to Misc</BackButton>
        {selected.content}
        <BackButton onClick={goToMisc}>Back to Misc</BackButton>
        </>
    );
}

export default MiscView;