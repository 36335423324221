import React from 'react';
import styled from 'styled-components';

import MirrorImage from '../../../assets/images/misc/mirrorimagetwzone.png'

import Card from '../../../components/generic/Card';
import { BlogContent, BlogText, Spacer, P, I } from '../../../components/blog/utils';

const Background = styled.div`
display: inline-block;
    opacity: 0.9;
    background: #fff url(${MirrorImage}) no-repeat center;
    background-size: contain;
    min-height: 250px;
    min-width: 250px;
    margin: 0 auto;
`

const ModelOfYou = () => {

    return (
        <Card>
            <BlogContent>
                <BlogText>
                    <h2>The Model of You</h2>
                    <Spacer/>
                    <Background/>
                    <Spacer/>
                    <P>The image above is taken from the Twilight Zone episode, Mirror Image. A fragment from the opening narration follows.</P>
                    <Spacer/>
                    <I>Circumstances will assault her sense of reality and a chain of nightmares will put her sanity on a block. Millicent Barnes, who, in one minute, will wonder if she's going mad.</I>
                    <Spacer/>
                    <P>I won't detail the episode further as it is most definitely worth a watch. Every Twilight Zone episode is.</P>
                    <Spacer/>
                    <P>Yet the image makes us question. Would you recognize yourself? Our perspective is first person. 
                        We see our nose, cheeks, lips, arms, torso, and legs.</P>
                    <Spacer/>
                    <P>
                        How well can you recall your face? Each hair as it grows, bags under your eyes from a lack of sleep. 
                        Take a moment to try and picture your own face as vividly as possible [like a photograph]. 
                    </P>
                    <Spacer/>
                    <P>Close your eyes, try it.</P>
                    <Spacer/>
                    <P>Our memories are just a loose ball of yarn. Surely a mirror allows us to see the full picture [just as in the image above]. However, at one time you may not have recognized yourself.</P>
                    <Spacer/>
                    <P>The <a href="https://en.wikipedia.org/wiki/Mirror_test#Rouge_test" target="_blank">Rouge Test</a> [<a href="https://www.youtube.com/watch?v=M2I0kwSua44" target="_blank">video here</a>]
                        is a technique used to determine visual self-awareness. Prior to 18 months, baby humans tend to not recognize themselves.
                    </P>
                    <Spacer/>
                    <P>
                        Is a mirror just a device to recognize visual changes? The recognized self isn't us. At most its just a perfect reflection of light. 
                        You view a <b>reflection</b> of yourself. 
                    </P>
                    <Spacer/>
                    <P>Provided that they materialized in front of you.</P>
                    <Spacer/>
                    <P>Would you recognize:</P>
                    <Spacer/>
                    <P>• The un-reflected version of yourself</P>
                    <Spacer/>
                    <P>• You at age 1</P>
                    <Spacer/>
                    <P>• You at age 4</P>
                    <Spacer/>
                    <P>• You at age 16</P>
                    <Spacer/>
                    <P>• You at age 64</P>
                    <Spacer/>
                    <P>I feel for Millicent Barnes. The idea of a Doppelgänger frightens me to the core. Regardless if they are a friendly stranger or not. It pierces me deep into something primal. To reflect on it fills me with pure terror.</P>
                    <Spacer/>
                </BlogText>
            </BlogContent>
        </Card>
    )
}

export default ModelOfYou;