import { Command } from './Command';
import { Arguments } from './Arguments';
import {history} from '../env';

export default class HistoryCommand extends Command {
    usage: string = 'history';
    trigger: string = 'history';
    
    execute(args: Arguments): string[] {
        let hist: [][] = history.getHistory();
        let result: any[] = hist.map((entry,index) => {
            return ` ${index + 1}.  ${entry.join('')}`;
        })

        return result;
    }

}