import * as React from 'react';
import styled from 'styled-components';

import {Film, films} from './films';
import Card from '../../../components/generic/Card';

const FilmItem = styled.div`
    padding: 1rem;
`;


const Film = () => {
    return (
        <Card>
            {films.map(film => {
                return <FilmItem>{`${film.title} - [${film.date}] - ${film.length}`}</FilmItem>
            })}
        </Card>
    );
}

export default Film;