
export default class History {
    private entries: [][] = [];
    private index: number = 0;
    private size: number = 10;

    public next = () => {
        const idx = Math.min(this.entries.length-1, this.index + 1);
        this.index = idx;
        return this.entries[this.index];
    }   

    public prev = () => {
        const idx = Math.max(0, this.index - 1);
        this.index = idx;
        return this.entries[this.index];
    }

    public push = (element: []) => {
        this.entries.push(element);
        if(this.entries.length > this.size) {
            this.entries.pop();
        }
        this.index = this.entries.length;
    }

    public update = (content: []) => {
        this.entries[this.index] = content;
    }

    public remove = () => {
        this.entries.splice(this.index,1);
    }

    public getHistory = () => {
        return this.entries;
    }
}