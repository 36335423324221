import * as React from 'react';
import styled from 'styled-components';

import maxbunny from '../../assets/images/maxbunny.png';
import Card from './Card';


const Background = styled.div`
    opacity: 0.9;
    background: #fff url(${maxbunny}) no-repeat center;
    background-size: contain;
    min-height: 50vh;
    min-width: 50vw;
    margin: 0 auto;
`

const AboutMeTitle = styled.div`
    padding-top: 0.25em;
    text-decoration: underline;
    text-decoration-style: double;

    font-size: 2.5em;
`

const AboutMeList = styled.ul`
    display: list-item;
    list-style: none;
    margin: 0.5em;

    & > li {
        display: list-item;
        padding: 1em 0em;
    }
`  

const AboutMeListItem = styled.li`  

` 

const AboutMeContent = styled.span`
    font-size: 1.25em;
    word-wrap: break-word
`



const AboutMe = () => {
    return (
        <Card>
            <AboutMeTitle>
                About
            </AboutMeTitle>

            <AboutMeList>
            <AboutMeListItem>
                    <AboutMeContent>
                        Is an entity
                    </AboutMeContent>
                </AboutMeListItem>
                <AboutMeListItem>
                    <AboutMeContent>
                        Wishes to find and discover.
                    </AboutMeContent>
                </AboutMeListItem>
                <AboutMeListItem>
                    <AboutMeContent>
                        Accepts that they don't know, how much they know.
                    </AboutMeContent>
                </AboutMeListItem>
                <AboutMeListItem>
                    <AboutMeContent>
                        Innate desire to share beauty [perhaps intersubjective].
                    </AboutMeContent>
                </AboutMeListItem>
                <AboutMeListItem>
                    <AboutMeContent>
                        Believes the present is infinite, the past never existed, and that the future will never come.
                    </AboutMeContent>
                </AboutMeListItem>
            </AboutMeList>
            <Background/>
        </Card>
    )
}

export default AboutMe;