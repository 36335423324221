import * as React from 'react';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
    display: flex;           
    flex-direction: column;  
    justify-content: center; 
    align-items: center;
`;

const SvgElem = styled.svg`
    height: 100vh;
    width: 100vw;
`;

const Circle = styled.circle`
    
`;

const Group = styled.g`
    transition: all .5s linear;
    &:active {
        transform:rotate(180deg);
    }
`;

class ErrorBoundary extends React.Component {
    state = { hasError: false };
    
    constructor(props) {
      super(props);
      
    }
  
    static getDerivedStateFromError(error) {
      
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.error(error, errorInfo);
    }
  
    toRoot = () => {
        location.assign('/');
    }

    render() {
      if (this.state.hasError) {
        
        return (
            <ErrorWrapper onClick={this.toRoot}>
                <SvgElem viewBox="0 0 500 500">
                    <Group>
                        <Circle cx="50%" cy="50%" r="49%" fill="#fdcb6e"/>
                        <text x="50%" y="50%" text-anchor="middle" stroke="#00000" stroke-width="2em" dy=".3em">The page has crashed.</text>
                        <text x="50%" y="50%" text-anchor="middle" stroke="#00000" stroke-width="2em" dy="3em"> Click me to refresh. </text>
                    </Group>
                </SvgElem>
            </ErrorWrapper>
        );
      }
  
      return this.props.children; 
    }
  }

export default ErrorBoundary;