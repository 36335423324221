import * as React from 'react';
import styled from 'styled-components';
import {pageLoadAnimation} from '../../core/animations';
import TriangleImage from '../../assets/TriangleImage';

const HomeWrapper = styled.div`
    ${pageLoadAnimation}
    width: 100%;
    height: 100%;
`;

const Home: React.FC = () => {
    return (
        <HomeWrapper>
            <TriangleImage/>
        </HomeWrapper>
    );
}

export default Home;