import ThinkingFastAndSlow from './ThinkingFastandSlowDanielKahneman.pdf';
import ThinkingFastAndSlowImage from '../../assets/images/books/thinkingfastandslow.jpg';

import DeepWork from './DeepWork.pdf';
import DeepWorkImage from '../../assets/images/books/deepwork.jpg';

import SlightEdge from './TheSlightEdge.pdf'
import SlightEdgeImage from '../../assets/images/books/slightedge.jpg';

import Lying from './SamHarrisLying.pdf';
import LyingImage from '../../assets/images/books/lying.jpg';

import ObjectThinking from './ObjectThinking.pdf';
import ObjectThinkingImage from '../../assets/images/books/objectthinking.jpeg';

import HowToReadABook from './HowtoReadaBook.pdf';
import HowToReadABookImage from '../../assets/images/books/howtoreadabook.png';

import OnWritingWell from './OnWritingWellTheClassicGuidetoWritingNonfiction.pdf';
import OnWritingWellImage from '../../assets/images/books/onwritingwell.jpg';

import HowToLiveOn24Hours from './Livingon24Hoursaday.pdf';
import HowToLiveOn24HoursImage from '../../assets/images/books/howtoliveon24hoursaday.jpeg';

import TheRationalMale from './TheRationalMale.pdf';
import TheRationalMaleImage from '../../assets/images/books/therationalmale.jpg';

import TurningPro from './TurningPro.pdf';
import TurningProImage from '../../assets/images/books/turningpro.jpg';

import AtomicHabits from './AtomicHabits.pdf';
import AtomicHabitsImage from '../../assets/images/books/atomichabits.png';

import TheForgettingMachine from './TheForgettingMachine.pdf';
import TheForgettingMachineImage from '../../assets/images/books/forgettingmachine.jpg';

import UnlimitedMemory from './UnlimitedMemory.pdf';
import UnlimitedMemoryImage from '../../assets/images/books/unlimitedmemory.jpg';

import SimulacraAndSimulation from './SimulacraAndSimulation.pdf';
import SimulacraAndSimulationImage from '../../assets/images/books/simulacres_et_simulation.jpg';

import ZenandtheArtofMotorcycleMaintenance from './ZenandtheArtofMotorcycleMaintenance.pdf';
import ZenandtheArtofMotorcycleMaintenanceImage from '../../assets/images/books/zenandtheartofmotorcyclemaintenance.jpg';

import HoldOntoYourKids from './HoldOntoYourKidsWhyParentsneedtomattermoreThanPeers.pdf';
import HoldOntoYourKidsImage from '../../assets/images/books/hold_onto_your_kids.jpg';

import PermissionToFeel from './PermissiontoFeelUnlockingthePoweroftoHelpOurKidsOurselvesandOurSocietyThrive.pdf';
import PermissionToFeelImage from '../../assets/images/books/permissiontofeelcover.jpg';

import TheSelfishGene from './TheSelfishGene.pdf';
import TheSelfishGeneImage from '../../assets/images/books/the_selfish_gene.jpg';

import CantHurtMe from './CantHurtMe.pdf';
import CantHurtMeImage from '../../assets/images/books/canthurtme.jpg';

import TheCourageToBeDisliked from './TheCourageToBeDisliked.pdf';
import TheCourageToBeDislikedImage from '../../assets/images/books/thecouragetobedisliked.jpg';

enum MediaType {
    PDF,
    MARKDOWN,
}

interface Book {
    title: string;
    content: any;
    image: any;
    author: string;
    type: MediaType;
}

interface KeyToBookMap {
    [key: string]: Book;
}

const books: KeyToBookMap = {}

books['objectthinking'] = {
    title: 'Object Thinking',
    content: ObjectThinking,
    image: ObjectThinkingImage,
    author: 'David West',
    type: MediaType.PDF

}

books['thinkingfastandslow'] = {
    title: 'Thinking, Fast and Slow',
    content: ThinkingFastAndSlow,
    image: ThinkingFastAndSlowImage,
    author: 'Daniel Kahneman',
    type: MediaType.PDF
};

books['deepwork'] = {
    title: 'Deep Work',
    content: DeepWork,
    image: DeepWorkImage,
    author: 'Cal Newport',
    type: MediaType.PDF
};

books['holdontoyourkids'] = {
    title: `Hold On to Your Kids Why Parents Need to Matter More than Peers`,
    content: HoldOntoYourKids,
    image: HoldOntoYourKidsImage,
    author: 'Gordon Neufield and Gabor Mate',
    type: MediaType.PDF  
}

books['theslightedge'] = {
    title: 'The Slight Edge',
    content: SlightEdge,
    image: SlightEdgeImage,
    author: 'Jeff Olson',
    type: MediaType.PDF

}

books['lying'] = {
    title: 'Lying',
    content: Lying,
    image: LyingImage,
    author: 'Sam Harris',
    type: MediaType.PDF

}

books['howtoreadabook'] = {
    title: 'How To Read A Book',
    content: HowToReadABook,
    image: HowToReadABookImage,
    author: 'Mortimer Adler',
    type: MediaType.PDF
}

books['writingwell'] = {
    title: 'On Writing Well, The Classic Guide to Writing Nonfiction',
    content: OnWritingWell,
    image: OnWritingWellImage,
    author: 'William Zinsser',
    type: MediaType.PDF

}

books['howtoliveon24hoursaday'] = {
    title: 'How to Live on 24 Hours a Day',
    content: HowToLiveOn24Hours,
    image: HowToLiveOn24HoursImage,
    author: 'Arnold Bennett',
    type: MediaType.PDF

}

// books['therationalmale'] = {
//     title: 'The Rational Male',
//     content: TheRationalMale,
//     image: TheRationalMaleImage,
//     author: 'Rollo Tomassi',
//     type: MediaType.PDF
// }

books['turningpro'] = {
    title: `Turning Pro: Tap Your Inner Power and Create Your Life's Work`,
    content: TurningPro,
    image: TurningProImage,
    author: 'Steven Pressfield',
    type: MediaType.PDF
}

books['atomichabits'] = {
    title: `Atomic Habits: An Easy & Proven Way to Build Good Habits & Break Bad Ones`,
    content: AtomicHabits,
    image: AtomicHabitsImage,
    author: 'James Clear',
    type: MediaType.PDF
}

books['theforgettingmachine'] = {
    title: `The Forgetting Machine: Memory, Perception, and the "Jennifer Aniston Neuron"`,
    content: TheForgettingMachine,
    image: TheForgettingMachineImage,
    author: 'Rodrigo Quian Quiroga',
    type: MediaType.PDF
   
}

books['unlimitedmemory'] = {
    title: `Unlimited Memory: How to Use Advanced Learning Strategies to Learn Faster, Remember More and be More Productive`,
    content: UnlimitedMemory,
    image: UnlimitedMemoryImage,
    author: 'Kevin Horsley',
    type: MediaType.PDF
}

books['simulacraandsimulation'] = {
    title: `Simulacra and Simulation`,
    content: SimulacraAndSimulation,
    image: SimulacraAndSimulationImage,
    author: 'Jean Baudrillard',
    type: MediaType.PDF
}

books['zenandtheartofmotorcyclemaintenance'] = {
    title: `Zen and the Art of Motorcycle Maintenance`,
    content: ZenandtheArtofMotorcycleMaintenance,
    image: ZenandtheArtofMotorcycleMaintenanceImage,
    author: 'Robert M Pirsig',
    type: MediaType.PDF
}

books['permissiontofeel'] = {
    title: `Permission to Feel: Unlocking the Power of Emotions to Help Our Kids, Ourselves, and Our Society Thrive`,
    content: PermissionToFeel,
    image: PermissionToFeelImage,
    author: 'Marc Brackett',
    type: MediaType.PDF
}

books['theselfishgene'] = {
    title: `The Selfish Gene`,
    content: TheSelfishGene,
    image: TheSelfishGeneImage,
    author: 'Richard Dawkins',
    type: MediaType.PDF
}

books['canthurtme'] = {
    title: `Can't Hurt Me`,
    content: CantHurtMe,
    image: CantHurtMeImage,
    author: 'David Goggins',
    type: MediaType.PDF
}

books['thecouragetobedisliked'] = {
    title: `The Courage To Be Disliked`,
    content: TheCourageToBeDisliked,
    image: TheCourageToBeDislikedImage,
    author: 'Ichiro Kishimi',
    type: MediaType.PDF
}

export {Book, books, MediaType}