import React, { useRef, useState, useContext, useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from "react-router-dom";

import color from '../../core/colors';

import { terminalStyles, textWrap } from './TerminalStyles';

import RandomText from '../fun/RandomText';
import {F,B} from '../fun/SpinText';

import HistoryController from './HistoryController';
import LineBuffer from './LineBuffer';
import {commandContext, history} from './env';
import SpecialBanner from '../fun/SpecialBanner';

const Container = styled.div`
    background-color: ${color.black};
    cursor: text;
    min-height: 20rem;
    max-height: 20rem;
    overflow: auto;
    overflow-y: scroll;
`;

const Line = styled.div`
    display: inline-block;
    min-width: 100%;
    ${terminalStyles}
`;

const LineText = styled.pre`
    float: left;
    ${textWrap}
    ${terminalStyles}
`;

interface TerminalContainerProps {
    scrollRef: React.Ref<HTMLDivElement>
}

const TerminalContainerContext = React.createContext(null);

const TerminalContainer: React.FC<TerminalContainerProps> = (props) => {
    const [ref, setRef] = useState(null);
    const mousePress = (event: React.MouseEvent) => {
        ref.current.focus();
    }

    return (
        <Container onClick={mousePress} ref={props.scrollRef}>
            <TerminalContainerContext.Provider value={setRef}>
                {props.children}
            </TerminalContainerContext.Provider>
        </Container>
    );
}

const Terminal: React.FC = () => {
    const [lines,setLines] = useState([]);
    let location = useLocation();
    const [path,setPath] = useState(location.pathname);

    const termHistory = useRef(history);
    const bottomLineRef: React.Ref<HTMLDivElement> = useRef();
    
    useEffect(
        () => {
          if (bottomLineRef.current) {
            bottomLineRef.current.scrollTop = bottomLineRef.current.scrollHeight;
          }
          
        },
        [lines]
      );
    useEffect(
        () => {
            if(location.pathname === '/') {
                let dir = commandContext.directory;
                commandContext.directory = dir.getRoot(dir);
                setPath(commandContext.directory.path);
                return;
            }
            
            let navigated = commandContext.directory.navigateAbsolute(location.pathname);

            if(navigated !== null) {
                commandContext.directory = navigated;
                setPath(navigated);
            } else {
                let failed = commandContext.directory.findNestedChild(commandContext.directory,['/404']);
                commandContext.directory = failed;
                setPath(failed.path);
            }
        },
        [location]
    );
    
    const addLine = (command,output) => {
        let newLines = [];
        newLines.push(`user@maxgdn~${path}$ ${command.join('')}`);
        termHistory.current.push(command);
        newLines = [...newLines,...output];
        setLines([...lines, ...newLines]);
    }
    return (
        <TerminalContainer scrollRef={bottomLineRef}>
            <Line><LineText><SpecialBanner/></LineText></Line>
            <Line><LineText>Try typing <b>help</b> for a list of all commands</LineText></Line>
            <Line><LineText><b>Mobile support not yet added, to try it out use a device with a physical keyboard</b></LineText></Line>
            {lines.map(l => {
                return <Line><LineText children={l}/></Line>
            })}
            <Line><LineText children={`user@maxgdn:~${path}$`}/><LineBuffer addLine={addLine} termHistory={termHistory}/></Line>
        </TerminalContainer>
    );
}

export default Terminal;