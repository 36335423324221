import * as React from 'react';
import styled from 'styled-components';
import WordBoard from '../../../components/wordwall/WordBoard';

const WordWall = () => {
    return (
        <>
        <h1>Word Wall</h1>
        <p>A place to appreciate encountered words.</p>
        <p>Click on a word for its definition.</p>
        <WordBoard/>
        </>
    )
}

export default WordWall;