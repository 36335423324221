interface Word {
    name: string;
    definition: string;
}

const words: Word[] = [
    { name: `temperament`, definition: `a person's or animal's nature, especially as it permanently affects their behavior.`},
    { name: `mercurial`, definition: `(of a person) subject to sudden or unpredictable changes of mood or mind.`},
    { name: `ambivalent`, definition: `having mixed feelings or contradictory ideas about something or someone.`},
    { name: `opine`, definition: `hold and state as one's opinion.`},
    { name: `ensconced`, definition: `establish or settle (someone) in a comfortable, safe, or secret place.`},
    { name: `ephemeral`, definition: `lasting for a very short time.`},
    { name: `bastion`, definition: `an institution, place, or person strongly defending or upholding particular principles, attitudes, or activities.`},
    { name: `peril`, definition: `serious and immediate danger.`},
    { name: `clandestine`, definition: `kept secret or done secretively, especially because illicit.`},
    { name: `reverie`, definition: `a state of dreamy meditation or fanciful musing.`},
    { name: `mesmerize`, definition: `hold the attention of (someone) to the exclusion of all else or so as to transfix them.`},
    { name: `delightful`, definition: `causing delight; charming.`},
    { name: `ethereal`, definition: `extremely delicate and light in a way that seems too perfect for this world.`},
    { name: `debonair`, definition: `confident, stylish, and charming (typically used of a man).`},
    { name: `aloof`, definition: `not friendly or forthcoming; cool and distant.`},
    { name: `zenith`, definition: `the time at which something is most powerful or successful.`},
    { name: `abominable`, definition: `causing moral revulsion.`},
    { name: `wizardry`, definition: `great skill in a particular area of activity.`},
    { name: `berserk`, definition: `out of control with anger or excitement; wild or frenzied.`},
    { name: `calamity`, definition: `an event causing great and often sudden damage or distress; a disaster.`},
    { name: `conundrum`, definition: `a confusing and difficult problem or question.`},
    { name: `doppelganger`, definition: `an apparition or double of a living person.`},
    { name: `flabbergasted`, definition: `greatly surprised or astonished.`},
    { name: `gizmo`, definition: `a gadget, especially one whose name the speaker does not know or cannot recall.`},
    { name: `halcyon`, definition: `denoting a period of time in the past that was idyllically happy and peaceful.`},
    { name: `haphazard`, definition: `lacking any obvious principle of organization.`},
    { name: `lambasted`, definition: `criticize (someone or something) harshly.`},
    { name: `magnificent`, definition: `impressively beautiful, elaborate, or extravagant; striking.`},
    { name: `mnemonic`, definition: `a device such as a pattern of letters, ideas, or associations that assists in remembering something.`},
    { name: `wither`, definition: `cease to flourish; fall into decay or decline.`},
    { name: `gambit`, definition: `a device, action, or opening remark, typically one entailing a degree of risk, that is calculated to gain an advantage.`},
    { name: `huckster`, definition: `promote or sell (something, typically a product of questionable value).`},
    { name: `enigma`, definition: `a person or thing that is mysterious, puzzling, or difficult to understand.`},
    { name: `opulent`, definition: `ostentatiously rich and luxurious or lavish.`},
    { name: `snooping`, definition: `the action of furtively trying to find out something, especially information about someone's private affairs.`},
    { name: `deity`, definition: `divine status, quality, or nature.`},
    { name: `verisimilitude`, definition: `the appearance of being true or real.`},
    { name: `sycophant`, definition: `a person who acts obsequiously toward someone important in order to gain advantage.`},
    { name: `diatribe`, definition: `a forceful and bitter verbal attack against someone or something.`},
    { name: `despondent`, definition: `in low spirits from loss of hope or courage.`},
    { name: `adulation`, definition: `obsequious flattery; excessive admiration or praise.`},
    { name: `obsequious`, definition: `obedient or attentive to an excessive or servile degree.`},
    { name: `elucidate`, definition: `make (something) clear; explain.`},
    { name: `bombastic`, definition: `high-sounding but with little meaning; inflated.`},
    { name: `lugubrious`, definition: `looking or sounding sad and dismal.`},
    { name: `languid`, definition: `(of a person, manner, or gesture) displaying or having a disinclination for physical exertion or effort; slow and relaxed.`},
    { name: `maelstrom`, definition: `a situation or state of confused movement or violent turmoil.`},
    { name: `peruse`, definition: `read (something), typically in a thorough or careful way.`},
    { name: `epoch`, definition: `a period of time in history or a person's life, typically one marked by notable events or particular characteristics.`},
    { name: `serenity`, definition: `the state of being calm, peaceful, and untroubled.`},
    { name: `eloquence`, definition: `fluent or persuasive speaking or writing.`},
    { name: `premiate`, definition: `to give a prize or premium to or for.`},
    { name: `captious`, definition: `(of a person) tending to find fault or raise petty objections.`},
    { name: `spadish`, definition: `direct and blunt in manner of expression`},
    { name: `arcadia`, definition: `a region or scene of simple pleasure and quiet`},
    { name: `gilded`, definition: `wealthy and privileged.`},
    { name: `self-discovery`, definition: `the process of acquiring insight into one's own character.`},
    { name: `candor`, definition: `the quality of being open and honest in expression; frankness.`},
    { name: `draconic`, definition: `(of a law or treatment) very harsh and severe.`},
    { name: `lament`, definition: `a passionate expression of grief or sorrow.`},
    { name: `sanguine`, definition: `optimistic or positive, especially in an apparently bad or difficult situation.`},
    { name: `tact`, definition: `adroitness and sensitivity in dealing with others or with difficult issues.`},
    { name: `blasé`, definition: `unimpressed or indifferent to something because one has experienced or seen it so often before.`},
    { name: `auspicious`, definition: `conducive to success; favorable.`},
    { name: `conflagration`, definition: `an extensive fire which destroys a great deal of land or property.`},
    { name: `heretic`, definition: `a person believing in or practicing religious heresy.`},
    { name: `pariah`, definition: `an outcast.`},
    { name: `invoke`, definition: `cite or appeal to (someone or something) as an authority for an action or in support of an argument.`},
    { name: `cupidity`, definition: `greed for money or possessions.`},
    { name: `itinerant`, definition: `traveling from place to place.`},
    { name: `harmony`, definition: `the quality of forming a pleasing and consistent whole.`},
    { name: `dearth`, definition: `a scarcity or lack of something.`},
    { name: `pastiche`, definition: `imitate the style of (an artist or work).`},
    { name: `kerning`, definition: `the spacing between letters or characters in a piece of text to be printed.`},
];

export {words, Word};