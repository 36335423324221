import React from 'react';
import styled from 'styled-components';

import VerticalUpGmaw from '../../../assets/images/welding/3f_gmaw.jpg';
import VerticalDownButtJointGmaw from '../../../assets/images/welding/3g_butt_down_gmaw.jpg';
import OvearheadSmaw from '../../../assets/images/welding/4f_smaw.jpg';

import {
    Top,
    ImageContainer,
    ImageItem,
    ImageItemImage,
    ImageItemTitle,
    ItemTypeA
} from '../../../components/blog/image';


const Welding = () => {
    return (
        <Top>
            <ImageContainer>
                <ItemTypeA image={VerticalUpGmaw} title={'Vertical Up [3F] - GMAW'}/>
                <ItemTypeA image={VerticalDownButtJointGmaw} title={'Vertical Down Butt Joint [3G] - GMAW'}/>
                <ItemTypeA image={OvearheadSmaw} title={'Overhead [4F] - SMAW'}/>
            </ImageContainer>
        </Top>
    );
}

export default Welding;