import * as React from 'react';
import styled from 'styled-components';

import Card from '../../../components/generic/Card';
import maxclock from '../../../assets/images/max_cuckoo_clock.jpg';
import { BlogContent, BlogText, Spacer, P, SongBlock } from '../../../components/blog/utils';

const Background = styled.div`
display: inline-block;
    opacity: 0.9;
    background: #fff url(${maxclock}) no-repeat center;
    background-size: contain;
    min-height: 220px;
    min-width: 220px;
    margin: 0 auto;
`

const TimeIsFickle = () => {
    return (
        <Card>
            <BlogContent>
                <BlogText>    
                    <h2>So fickle</h2>
                    <Spacer/>            
                    <P>
                    Do Lions hunt at 1 a.m ? Are they choosing to do so because its 1 a.m ? 
                    </P>
                    <P>
                    Lions may feel pride and shame, but they don't fret over getting up late at 2 a.m to hunt (They just hunt!).
                    </P>
                    <Spacer/>
                    <P>
                    Time is always changing.
                    </P>
                    <P>
                    Atomic clocks are accurate, the world goes 'round, your stopwatch will need to be cranked and continually drifts away from the "right" time.
                    </P>
                    <P>
                    We observe changes and accept that there are differences. Entropy is always increasing.
                    </P>
                    <Spacer/>
                    <P>
                    Yet our egos destroy us over the smallest of differences. 
                    </P>
                    <Spacer/>
                    <P>
                    You miss the bus (Shame).
                    </P>
                    <Spacer/>
                    <P>
                    You finish your morning routine 30 minutes early (Pride).
                    </P>
                    <Spacer/>
                    <P>
                    You're right on time for an event (Relief and potential pride from averting shame [loss aversion]).
                    </P>
                    <Spacer/>
                    
                    <P>
                    When trying to type a certain WPM (Words per minute) do you feel each key stroke firing from your brain into your finger tips and back? 
                    </P>
                    <P>
                    Can you internalize the difference in time? The delay in milliseconds? [This was typed too slowly, can't you tell how long it took!]
                    </P>
                    <Spacer/>
                    <P>
                    Having that report done by 4 p.m so an end client can receive it earlier and the firm can turn a profit.
                    </P>
                    <P>
                    How many people are anchored to time in this situation?
                    </P>
                    <Spacer/>
                    <P>
                    We shouldn't accept these dreadful constraints from society and our egos. 
                    </P>
                    <Spacer/>
                    <P>
                    Our scope is local:
                    </P>
                    <Spacer/>
                    <P>
                    • Thirsty -&gt; get water.
                    </P>
                    <Spacer/>
                    <P>
                    • Hungry -&gt; eat food.
                    </P>
                    <Spacer/>
                    <P>
                    • Curious -&gt; discover something.
                    </P>
                    <Spacer/>                    
                    <P>
                    The farther the scope increases the less it makes sense. 
                    </P>
                    <Spacer/>
                    <P>
                    • Right now I will be healthier.
                    </P>
                    <Spacer/>
                    <P>
                    • Today I will be healthier.
                    </P>
                    <Spacer/>
                    <P>
                    • Tomorrow I will be healthier.
                    </P>
                    <Spacer/>
                    <P>
                    • Next week I will be healthier.
                    </P>
                    <Spacer/>
                    <P>
                    • Next month I will be healthier.
                    </P>
                    <Spacer/>
                    <P>
                    • In a year I will be healthier.
                    </P>
                    <Spacer/>
                    <P>
                    The last item can only be achieved by impacting the current moment. 
                    </P>
                    <P>
                    Focusing on the task at hand wholeheartedly without concern for the future and past can bring one comfort and the right frame of mind. Be kind to oneself.
                    </P>
                    <Spacer/>
                    <P>
                    Excerpt from <a href="https://www.youtube.com/watch?v=tk8fxaU_EW0" target="_blank">Pogo - Sing Songs</a> [sampled from The Perfect Nanny - Mary Poppins]
                    </P>
                    <Spacer/>
                    <SongBlock>
                    {`You can change everything.
                    Just love every living thing.
                    Money don't mean a thing
                    If you don't have a song to sing.
                    
                    Nothing's enough.
                    Nothin' fits like a glove.
                    Then the sandpaper's too rough
                    And the jerky is too tough.
                    Live today like tomorrow,
                    And you'll find eternal sorrow
                    'Cause you might win the future
                    Like you'll never win the lotto.

                    Bein' in the minute
                    Is the only way to live it.
                    'Cause the future makes a shadow
                    Of imaginary limit.
                    Live today like the end.
                    Let your senses comprehend
                    That the nature of the moment
                    Is your ticket to ascend.`}
                    </SongBlock>
                    <Spacer/>
                    <P>
                    I think I still need some more time to think about this...      
                    </P>
                    <Spacer/>
                    <Background/> 
            </BlogText>
            
        </BlogContent>
    </Card>
    )
}

export default TimeIsFickle;