import React from 'react';
import styled from 'styled-components';

import Card from '../../../components/generic/Card';
import { BlogContent, BlogText, Spacer, P, I } from '../../../components/blog/utils';

const LookingDown = () => {
    return (
        <Card>
            <BlogContent>
                <BlogText>
                    <h2>Looking Down</h2>
                    <Spacer/>
                    <P>
                        I mentored a Hackathon this past weekend. The team I ended up mentoring was working on a fullstack web application. 
                    </P>
                    <Spacer/>
                    <P>The original ticket I had received was something along the lines of <I>"Upload a picture to backend and process with google vision API"</I>.</P>
                    <Spacer/>
                    <P>We traversed many paths:</P>
                    <Spacer/>
                    <P>• IDE not saving any progress to files on disk [wat].</P>
                    <Spacer/>
                    <P>• Restructuring project layout.</P>
                    <Spacer/>
                    <P>• Configuring environment variables.</P>
                    <Spacer/>
                    <P>• Syncing git progress.</P>
                    <Spacer/>
                    <P>• Small requisites to satisfy ticket. What is a POST request, DOM events, Multipart form data, Event bubbling order, What is a port, testing, etc...</P>
                    <Spacer/>
                    <P>This was over the course of 6 hours. In the end the ticket requirements were satisfied and I departed.</P>
                    <Spacer/>
                    <P>A day and a bit later their project was beautiful and functioned as intended.</P>
                    <Spacer/>
                    <P>Many of the bumps in the road I had faced before. However, the experience left me feeling mighty and meek.</P>
                    <Spacer/>
                    <P>We had solved all of the issues, completed the ticket and the team had learned a lot.</P>
                    <Spacer/>
                    <P>I had once been in their shoes. Struggled with issues far more "trivial". It would be easy to believe that I've come far.</P>
                    <Spacer/>
                    <P>Yet, I still feel the pressure, helplessness, and thrill when solving issues. The difficulty is adjusted.</P>
                    <Spacer/>
                    <P>Looking down the mountain path looks the same as looking up.</P>
                    <Spacer/>
                    <P>Looking down, I'm glad I'm here. I'm glad I get to climb further.</P>
                </BlogText>
            </BlogContent>
        </Card>
    );
}

export default LookingDown;