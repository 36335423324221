import { Arguments } from "./Arguments";
import CommandContext from "./CommandContext";

export abstract class Command {
    context: CommandContext;
    usage: string;
    trigger: string;

    constructor(context: CommandContext) {
        this.context = context;
    }

    abstract execute(args: any): string | string[];
}