import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown'
import axios from '../../https';

interface MarkdownRendererProps {
    content: any;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = (props) => {
    const [post, setPost] = useState();
    
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(props.content);
            console.log(result.data);
            setPost(result.data);
        }

        fetchData();
    },[]);

    return (
        <Markdown source={post}/>
    )
}

export {MarkdownRenderer}