export default class Directory {
    path: string;
    parent?: Directory = null;
    children?: Directory[] = [];

    constructor(path: string) {
        this.path = path;
    }

    setParent(parent: Directory) {
        this.parent = parent;
    }

    addChild(child: Directory) {
        if(this.hasChild(child.path) === null) {
            child.parent = this;
            this.children.push(child);
        } else {
            throw('Child already exists');
        }
    }

    hasChild(path: string): Directory {
        console.log(`Looking for ${path}`);
        console.log(this.children);
        let c = this.children.filter(c => c.path === path);
        if(c.length === 0) return null;
        else return c[0];
    }

    buildPath(directory: Directory,names: string[]): string[] {
        if(directory.parent !== null){
            names.push(directory.path);
            return this.buildPath(directory.parent, names);
        } else return names;
    }

    navigateAbsolute(path: string): Directory {
        let dirs: string[] = path.split('/');
        let root = this.getRoot(this);
        dirs = dirs.map(s => {return '/' + s});
        dirs.shift();
        let target = root.findNestedChild(root,dirs);
        dirs = dirs.map(s => {return '/' + s});
        return target;
    }

    navigateRelative(path: string): Directory {
        let dirs: string[] = path.split('/');
        dirs = dirs.map(s => {return '/' + s});
        
        let [remaining,dir] = this.goBackward(dirs,this);
        if(remaining.length === 0) {
            return dir;
        } else {
            return dir.findNestedChild(dir,remaining);
        }
    }

    goBackward(path : string[], dir: Directory): [string[],Directory] {
        if(path[0] === '/..' && dir.parent !== null) {
            path.shift();
            return this.goBackward(path,dir.parent);
        } else {
            return [path,dir];
        }
    }

    findNestedChild(dir: Directory ,names: string[]) {
        let name = names[0];
        let c = dir.hasChild(name);
        if(names.length === 1) {
            return c;
        }
        if (c !== null) {
            names.shift();
            return this.findNestedChild(c,names);
        } else {
            return null;
        }
        
    }

    getRoot(dir: Directory) {
        if(dir.parent !== null) {
            return this.getRoot(dir.parent);
        }
        else return dir;
    }

    toString(): string {
        return this.buildPath(this,[]).reverse().join('');
    }
}

