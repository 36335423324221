import { Command } from './Command';
import { Arguments } from './Arguments';

export default class ListDate extends Command {
    usage: string = 'date';
    trigger: string = 'date';
    
    weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];

    monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    execute(args: Arguments): string {
        let now: Date = new Date();
        return `${now.getFullYear()} ${this.monthNames[now.getMonth()]} ${this.weekdays[now.getDay()]} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    }

}