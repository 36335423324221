import * as React from 'react';
import styled from 'styled-components';


interface IconButtonProps {
    icon?: React.ReactChild
    onClick?: any
}

const Span = styled.span`
    cursor: pointer;
    background-color: transparent;
`;

const IconButton: React.FC<IconButtonProps> = (props) => {
    return (
        <Span onClick={props.onClick}>
            {props.icon}
        </Span>
    );
}

export default IconButton;