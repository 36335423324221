import * as React from 'react';
import styled, { css } from 'styled-components';
import history from './core/history';

import { Router } from "react-router-dom";

import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Content from './components/layouts/Content';

import GlobalStyle from './core/GlobalStyle';

const background = css`
    background: #cfd9df;
    background: linear-gradient(#cfd9df, #e2ebf0);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    ${background}
`;

const Globals = () => {
    window.addEventListener('keydown', function(e) {
        if(e.keyCode == 32 && e.target == document.body) {
          e.preventDefault();
        }
    });
}


const App: React.FC = () => {
    Globals()
    return (
        <Router history={history}>
            <Container>
                <GlobalStyle/>
                <Header/>
                <Content/>
                <Footer/>
            </Container>
        </Router>
    );
}

export default App;