import React from 'react';
import {
    Route,
    Link,
    Switch,
    useRouteMatch
  } from "react-router-dom";
import styled from 'styled-components';

import {pageLoadAnimation} from '../../core/animations';

import {miscList, MiscItem} from '../../content/misc/misc';
import MiscView from './MiscView';
import RandomRandom from '../../content/misc/randomrandom/RandomRandom';

const MiscWrapper = styled.div`
    ${pageLoadAnimation}
    text-align: center;
    margin-top: 5rem;
`;


const MiscContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
`

const MiscElement = styled.div`
    margin: 1rem;
    flex: 1 1 200px;
`;

const MiscImage = styled.img`
    max-width: 200px;
    max-height: 200px;
    height: 100%;
    width: 100%;
    object-fit: cover;
`

const Misc: React.FC = () => {
    let { path, url } = useRouteMatch();

    return (
        <MiscWrapper>
            <Switch>
            <Route exact path={path}>
                <MiscContainer>
                    {miscList.map(item => {
                        return (
                            <MiscElement>
                                <Link to={`${url}/${item.key}`}>
                                    {item.image !== null ? <MiscImage src={item.image}/> : null}
                                    <p>{item.title}</p>
                                </Link>
                            </MiscElement>
                        )
                    })}
                    
                </MiscContainer>
            </Route>
            <Route path={`${path}/randomrandom`}>
                <RandomRandom/>
            </Route>
            
            <Route path={`${path}/:misc`}>
                <MiscView/>
            </Route>
            </Switch>
        </MiscWrapper>
    );
}

export default Misc;