import React from 'react';
import styled from 'styled-components';

import TooLate from '../../../assets/video/randomrandom/Turn_the_volume_up.mp4'

import {
    Link,
  } from "react-router-dom";

import { P } from '../../../components/blog/utils';

const Player = styled.video`
    width: 320px;
`;

const RandomRandom = () => {
    return (
        <>  
            <Player controls>
                <source src={TooLate} type="video/mp4"/>
            </Player>
            <P>Unscripted. 4am At the hotel, fresh out of a 12 hour road trip.</P>
            <Link to={'artifact'}>Context</Link>
        </>
    );
}

export default RandomRandom;