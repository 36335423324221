import React, { useRef, useState, useContext, useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';

import HistoryController from './HistoryController';

import { terminalStyles, textWrap } from './TerminalStyles';
import {commandContext} from './env';

interface LineBufferProps {
    addLine: any;
    termHistory: React.MutableRefObject<HistoryController>;
}

const Input = styled.pre`
    ${terminalStyles}
    ${textWrap}

`;

export const useWindowEvent = (event, callback) => {
    useEffect(() => {
      window.addEventListener(event, callback);
      return () => window.removeEventListener(event, callback);
    }, [event, callback]);
  };



const inject = (input: string) => {
    let response: string[] = [];
    input = input.trim();
    commandContext.commands.forEach(c => {
        if(input.startsWith(c.trigger)) {
            //split on first space
            let data: string | string[];
            const strs = input.split(' ');
            data = c.execute({command: strs.slice(1,input.length)});
            if(typeof data === 'string') {
                response = [data];
            } else {
                response = data;
            }
            
        }
    })
    if(response.length === 0) response = ["[Error] Command not found"];
    return response;
}

const initialIndexState = 0;

const indexReducer = (count, action) => {
    switch (action.type) { 
      case 'increment':
        if((count + 1) > action.buffer.length) return count;
        return count + 1;
      case 'decrement':
      if((count - 1) < 0) return count;
        return count - 1;
      case 'reset': 
        return 0;
      default:
        throw new Error();
    }
  }

const LineBuffer: React.FC<LineBufferProps> = (props) => {
    const inputRef = useRef(null);
    const [baud, setBaud] = useState(false);
    //const [index, move] = useReducer(indexReducer, initialIndexState);

    const [buffer, dispatch] = useReducer((buffer, { type, value , event}) => {
        switch (type) {
          case 'Backspace':
            buffer.pop();
            //move({type: 'decrement', buffer});
            return buffer;
          case 'Delete':
            return buffer;
          case 'Enter':
            const response = inject(buffer.join(''));
            props.termHistory.current.remove();
            props.addLine(buffer,response);
            return [];
          case 'ArrowUp':
            event.preventDefault();
            //move({type: 'reset', buffer});
            return props.termHistory.current.prev();
          case 'ArrowDown':
            event.preventDefault();
            //move({type: 'reset', buffer});
            return props.termHistory.current.next();
          case 'ArrowLeft':
            //move({type: 'decrement', buffer});
            return buffer;
          case 'ArrowRight':
            //move({type: 'increment', buffer});
            return buffer;
          case 'Shift':
            return buffer;
          case 'Escape':
            return buffer;
          case 'Alt':
            return buffer;
          case 'Meta':
            return buffer;
          case 'Control':
            return buffer;
          case 'CapsLock':
            return buffer;
          default:
            //buffer.splice(index, 0, value);
            //move({type: 'increment', buffer});
            return [...buffer, value];
        }
      }, []);
    useEffect(() => {
    const interval = setInterval(() => {
        setBaud((baud) => !baud);
    }, 750);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if(props.termHistory.current) props.termHistory.current.update(buffer);
    }, [buffer]);

    const handleKeyDownEvent = (event: React.KeyboardEvent) => {
        // console.log(buffer.join(''));
        // console.log(event);
        
        dispatch({type: event.key, value: event.key, event});
    }
    
    useWindowEvent('keydown', handleKeyDownEvent);
    let c = '';
    if(baud) c = '█';

    const text: string = ' ' + buffer.join('') + c;

    return (
        <Input tabIndex={0} ref={inputRef}>{text}</Input>
    );
}

export default LineBuffer;