import React, { useState, useEffect} from 'react';
import styled from 'styled-components';

const ColourableText = styled.span`
    color: ${(props) => props.color};
    font-size: 1.3rem;
    transition: color 1000ms linear;
`;

const RandomText: React.FC = (props) => {
    const [color, setColor] = useState('#ff1744');

    const colors = [
        '#ff1744',
        '#f50057',
        '#d500f9',
        '#651fff',
        '#2979ff',
        '#ff3d00',
        '#ffea00',
    ];

    useEffect(() => {
        const interval = setInterval(() => {
        let random: number = Math.floor(Math.random() * Math.floor(colors.length));
        setColor(colors[random]);
        }, 2000);

        return () => {
        clearInterval(interval);
        };
    }, []);

    return <ColourableText color={color}>{props.children}</ColourableText>;
}

export default RandomText;