import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

interface MenuProps {
  navbarState: boolean;
  handleNavbar: () => void;
}

const CollapseMenu: React.FC<MenuProps> = (props: MenuProps) => {
  if (props.navbarState === true) {
    return (
      <CollapseWrapper>
        <NavLinks>
            <li onClick={props.handleNavbar}><Link to={'/'} >Home</Link></li>
            <li onClick={props.handleNavbar} ><Link to={'/about'} >About</Link></li>
            <li onClick={props.handleNavbar}><Link to={'/misc'}>Misc</Link></li>
            <li onClick={props.handleNavbar}><Link to={'/books'}>Books</Link></li>
            <li onClick={props.handleNavbar}><Link to={'/projects'}>Projects</Link></li>
            
        </NavLinks>
      </CollapseWrapper>
    );
  }
  return null;
};

export default CollapseMenu;

const CollapseWrapper = styled.div`
  background: #2d3436;
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;
`;

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 4rem;

  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: #dfe6e9;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }
  }
`;