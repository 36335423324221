import { useRef, useEffect, useState } from "react"
import React from "react";
import styled, { css } from 'styled-components';
import WordDrawable from './WordDrawable';
import { horizontalScrollAnimationLeftToRight } from "../../core/animations";
import { State } from '@hookstate/core';
import { Word, words } from "./words";
import { shuffle } from "../../core/util";

const ScrollContainer = styled.div`
    overflow: hidden;
    width: 100%;
`;

const ScrollingText = styled.p`
    color: white;
    transform: translateX(100%);
    ${horizontalScrollAnimationLeftToRight}

    ${({ focused }) => focused && `
        animation-play-state: paused;
    `}
`;

const getWordDrawable = (): WordDrawable => {
    return new WordDrawable(shuffle(words)[0])
}



const ScrollingWord = (props: { wordDrawableState: State<WordDrawable>, focusedState: State<boolean>, currentWordState: State<Word> }) => {
    const ref = useRef()
    const wordDrawableState = props.wordDrawableState;
    const word = wordDrawableState.word.get()
    const colour = wordDrawableState.colour.get()
    const time = wordDrawableState.time.get()

    const focusedState = props.focusedState
    const focused = focusedState.get()

    const currentWordState = props.currentWordState

    useEffect(() => {
        console.log("STATE_UPDATE");
        //updateScrollingWord(time)
        const element: HTMLParagraphElement = ref.current;
        
        element.addEventListener('animationiteration', () => {
            const replacement = getWordDrawable()
            wordDrawableState.set(replacement)
            const element: HTMLParagraphElement = ref.current;
    
            //Reset animation
            element.style.animation = 'none';
            element.offsetHeight;
            element.style.animation = null;
          });
    },[]);
    
    console.log(`Time ${time}`)

    const onClick = () => {
        focusedState.set(s => !s);
        currentWordState.set(JSON.parse(JSON.stringify(word)))
    }

    return (
        <ScrollContainer>
            <ScrollingText ref={ref} color={colour} time={time} focused={focused} onClick={onClick}>{word.name}</ScrollingText>
        </ScrollContainer>
    );
}

export default ScrollingWord;