import React from 'react';
import styled, { css } from 'styled-components';

import {rotate3dAnimationForward,rotate3dAnimationBackward} from '../../core/animations';

const sharedP = css`
font-size: 1.3rem;
display:inline-block;
`;

const F = styled.span`
${sharedP}
${rotate3dAnimationForward}
`;

const B = styled.span`
${sharedP}
${rotate3dAnimationBackward}
`;

export { F,B };