import * as React from 'react';
import styled from 'styled-components';

const Card = styled.div`
overflow: hidden; 
background: #fff;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
border-radius: 10px;
margin: 1rem;
text-align: center;
`

export default Card;