import React from 'react';

import HungraiHippos from '../../assets/images/projects/hungraihungrai.jpg'
import BirdUp from '../../assets/images/projects/birdup.jpg'
import Tenty from '../../assets/images/projects/tenty.jpg'
import PocketQR from '../../assets/images/projects/pocketqr.jpg'
import I3Pedals from '../../assets/images/projects/i3pedals.jpg'
import Confession from '../../assets/images/projects/confession.png'


interface Project {
    title: string
    image: any;
    link: string
}

const projects: Project[] = [
    {title: `Hungr.ai Hungr.ai Hippos`, image: HungraiHippos, link: "https://devpost.com/software/hungr-ai"},
    {title: "BirdUp (Bird ↑)", image: BirdUp, link: "https://devpost.com/software/birdup"},
    {title: "Tenty", image: Tenty, link: "https://devpost.com/software/tenty"},
    {title: "PocketQR", image: PocketQR, link: "https://devpost.com/software/pocketqr"},
    {title: "i3_pedals", image: I3Pedals, link: "https://github.com/maxgdn/i3_pedals"},
    {title: "confession", image: Confession, link: "https://github.com/maxgdn/confession"},
] 

export {projects, Project}