import * as React from 'react';
import {useRef} from 'react';
import styled from 'styled-components';

import { createState, useState } from '@hookstate/core'

import {media, Media} from './media';


import TooLate from "../../../assets/video/media/An Honest Death A Palliative Care Doctor's Final Days.mp4"

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const ArrowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const Arrow = styled.div`
    margin: 30px;
    cursor: pointer;
`;

const Player = styled.video`
    width: 60%    !important;
    height: auto   !important;
`;

const Title = styled.h2`

`;

const Description = styled.div`
    margin: 10px;
`;

const mediaGlobalState = createState({
    index: 0,
    media: media
});

const Media = () => {
    const state = useState(mediaGlobalState);

    const videoRef = useRef();

    const index = state.index.get();
    const media = state.media.get();

    const back = () => {
        const videoElement: HTMLVideoElement = videoRef.current;
        state.index.set((p: number) => Math.max(0,(p - 1)));
        videoElement.load();
    };

    const forward = ()=> {
        const videoElement: HTMLVideoElement = videoRef.current;
        state.index.set((p: number) => Math.min((p + 1), media.length-1));
        videoElement.load();
    };

    const current = media[index];
    return (
        <VideoContainer>
            <Player controls ref={videoRef}>
                <source src={current.src} type="video/mp4"/>
            </Player>
            <Title>
                {current.title}
            </Title>
            <Description>
                {current.description}
            </Description>
            <ArrowContainer>
                <Arrow onClick={back}>🠰</Arrow>
                <Arrow onClick={forward}>🠲</Arrow>
            </ArrowContainer>
        </VideoContainer>
    );
}

export default Media;