import { Command } from './Command';
import { Arguments } from './Arguments';

export default class PrintWorkingDirectory extends Command {
    usage: string = 'pwd';
    trigger: string = 'pwd';
    
    execute(args: Arguments): string {
        let cwd = this.context.directory.toString();
        if(cwd === '') return '/';
        return cwd;
    }

}