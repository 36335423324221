import * as React from 'react';
import styled from 'styled-components';

import colors from '../../core/colors';

const Title = styled.h1`
    color: ${colors.primary};
    margin: auto 0;
`;

export default Title;