import { css } from 'styled-components';

import color from '../../core/colors';

export const terminalStyles = css`
    background-color: ${color.black};
    color: ${color.white};
    font-size: medium;
    font-family: monospace;
`;

export const textWrap = css`
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    hyphens: auto;
    white-space: pre-wrap;
`;