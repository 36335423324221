import * as React from 'react';
import styled from 'styled-components';

const Top = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0.75rem;
`

const ImageItem = styled.div`
    max-width: 320px;
    flex: 1 1 320px;
    margin: 1rem;
`

const ImageItemImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const ImageItemTitle = styled.div`
    overflow-wrap: break-word;
`

interface TypeA {
    image: any;
    title: string;
}

const ItemTypeA: React.FC<TypeA> = (props) => {
    return (
        <ImageItem>
            <ImageItemImage src={props.image}/>
            <ImageItemTitle>{props.title}</ImageItemTitle>
        </ImageItem>
    );
}


export {
    Top,
    ImageContainer,
    ImageItem,
    ImageItemImage,
    ImageItemTitle,
    ItemTypeA
}