import { Command } from "./Commands/Command";

import CommandContext from "./Commands/CommandContext";
import Directory from './Commands/Directory';
import HistoryController from './HistoryController';

import Echo from "./Commands/Echo";
import ListDate from "./Commands/ListDate";
import ChangeDirectory from "./Commands/ChangeDirectory";
import ListDirectory from './Commands/ListDirectory';
import Help from "./Commands/Help";
import PrintWorkingDirectory from "./Commands/PrintWorkingDirectory";
import UnixName from "./Commands/UnixName";
import HistoryCommand from "./Commands/HistoryCommand";
import WhoAmI from "./Commands/WhoAmI";

import {books} from '../../content/books/books';
import {miscList, MiscItem} from '../../content/misc/misc';

const commandContext: CommandContext = new CommandContext();
let commands: Command[] = [
    new Echo(commandContext),
    new ListDate(commandContext),
    new ChangeDirectory(commandContext),
    new ListDirectory(commandContext), 
    new PrintWorkingDirectory(commandContext),
    new UnixName(commandContext),
    new HistoryCommand(commandContext),
    new WhoAmI(commandContext),
];
commands.push(new Help(commandContext, commands));

let root = new Directory('/');

let about = new Directory('/about');
let projects = new Directory('/projects');

let booksDirectory = new Directory('/books');

//books from book object
Object.keys(books).forEach(k => {
    booksDirectory.addChild(new Directory(`/${k}`))
})

let misc = new Directory('/misc');

miscList.forEach((item: MiscItem) => {
    misc.addChild(new Directory(`/${item.key}`))
});

misc.addChild(new Directory(`/randomrandom`))


let fourofour = new Directory('/404');

root.addChild(about);
root.addChild(projects);
root.addChild(booksDirectory);
root.addChild(misc);
root.addChild(fourofour);

commandContext.setCommands(commands);
commandContext.setDirectory(root);

let history: HistoryController = new HistoryController();

export {commandContext,history};