import { Word } from './words';

const wordColours = [
    '#ff0000',
    '#ffa500',
    '#ffff00',
    '#008000',
    '#0000ff',
    '#4b0082',
    '#ee82ee',
]

const travelTime = 15;
const variance = 15;

function randomInteger(min: number, max: number): number {
    return (Math.random() * (max - min + 1)) + min;
}

class WordDrawable {
    word: Word;
    colour: string = "#FFFFFF";
    time: number;

    constructor(
        word: Word,
    ) {
        this.word = word;

        this.colour = wordColours[Math.floor(Math.random() * wordColours.length)];
        this.time = randomInteger(travelTime, travelTime + variance)
    }
}

export default WordDrawable;