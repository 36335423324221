import * as React from 'react';
import styled from 'styled-components';

import Card from '../../../components/generic/Card';
import adinfinum from '../../../assets/images/misc/adinfinum.jpg';
import { BlogContent, BlogText, Spacer, P } from '../../../components/blog/utils';

const Background = styled.div`
display: inline-block;
    opacity: 1;
    background: #fff url(${adinfinum}) no-repeat center;
    background-size: contain;
    min-height: 320px;
    min-width: 320px;
    margin: 0 auto;
`

const Tremendous = () => {
    return (
        <Card>
            <BlogContent>
                <BlogText>    
                    <h2>Tremendous</h2>
                    <Spacer/>            
                    <P>
                    Something tremendous is afoot. 
                    </P>
                    <Spacer/>
                    <P>
                    When you peer towards it, it blends, coalescing.
                    </P>
                    <Spacer/>
                    <P>
                    It cannot be spoken into the world, only molded with effort 
                    </P>
                    <Spacer/>
                    <P>
                    Do you have the courage to grasp it and hold onto it tightly?
                    </P>
                    <Spacer/>
                    <P>
                    If only we knew how close we are.
                    </P>
                    <Background/>
            </BlogText>
        </BlogContent>
    </Card>
    )
}

export default Tremendous;