import * as React from 'react';
import styled from 'styled-components';
import Card from '../../../components/generic/Card';

import { BlogContent, BlogText, Spacer, P, SongBlock } from '../../../components/blog/utils';

const Plan = () => {
    return (
        <Card>
            <BlogContent>
                <BlogText>
                <h2>The Plan</h2>
                <Spacer/>
                <P>
                    Often times the mind drifts. Diffused thinking is a great way to recuperate and change gears. 
                    However my mind is always adrift [At least it feels this way or I am imposing so].
                </P>
                <Spacer/>
                <P>
                    A heuristic I try to ask myself constantly is <b>What is the Plan?</b>
                </P>
                <Spacer/>
                <P>
                    It keeps the mind focused on what you should really be doing. 
                    It would be nice to not worry about "doing things". But, one must be self sustainable.
                </P>
                <Spacer/>
                <P>
                    The scope of this heuristic is local. I ask this question as often as possible. Upon answering it, both of the following can be asked.
                </P>
                <Spacer/>
                <P><b>What should I be doing?</b></P>
                <Spacer/>
                <P><b>Am I doing it?</b></P>
                <Spacer/>
                
                <Spacer/>
                <P>Now you may ask, but What is the Plan?</P>
                <Spacer/>
                <P>It is an unordered list without any time constraints.</P>
                <Spacer/>
                <P>My current list:</P>
                <Spacer/>
                <P>• Eat</P>
                <P>• Stretch</P>
                <P>• Skip</P>
                <P>• Lift weights</P>
                <P>• Work [programming]</P>
                <P>• Read</P>
                <P>• Accordion</P>
                <P>• Chinese characters</P>
                <P>• Maths</P>
                <P>• Revise plan</P>
                <Spacer/>
                <P>Both the list method and plan heuristic are anecdotal.</P>
                <Spacer/>
                <P>
                    Redefine what the implementation of the Plan is.
                    Try to generate your own heuristics to bring awareness of your current situation.
                </P>
                <Spacer/>
                <P>The end goal is to be mindful of your actions and accomplish what must be done.</P>
                <Spacer/>
                <P>
                    What's your Plan?
                </P>
                </BlogText>
            </BlogContent>
          
        </Card>
    );   
}

export default Plan;