import { Command } from './Command';
import { Arguments } from './Arguments';
import CommandContext from './CommandContext';

export default class Help extends Command {
    usage: string = 'help [string]';
    trigger: string = 'help';
    commands: Command[];

    constructor(commandContext: CommandContext, commands: Command[]) {
        super(commandContext)
        this.commands = commands;
    }
    
    execute(args: Arguments): string[] | string {
        //check length

        if((args.command.length === 1)) {
            let target = '';
            this.commands.forEach(cmd => {
                if(args.command[0] === cmd.trigger) target = cmd.usage;
            })
            return target;
        }

        if((args.command.length > 1)) {
            return "[Error] " + this.trigger + " accepts 1 or 0 arguments";
        }

        
        
        let helpStr: string[] = [];

        this.commands.forEach(cmd => {
            helpStr.push(cmd.usage);
        })

        return helpStr;
    }

}