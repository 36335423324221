import * as React from 'react';
import styled from 'styled-components';
import {pageLoadAnimation} from '../../core/animations';

import {projects, Project} from '../../content/projects/projects'

const ProjectsWrapper = styled.div`
    ${pageLoadAnimation}
`;

const ProjectContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ProjectItem = styled.div`
    margin: 1rem;
    flex: 1 1 200px;
    text-align: center;
`;

const ProjectImage = styled.img`
    max-width: 200px;
    max-height: 200px;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const To = styled.a`
    color: #0000000;
    text-decoration: none;

    &:visited { 
        text-decoration: none; 
        color: #0000000;
    }
`

const Stub = styled.div`
    max-width; 250px;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
`

const Title = styled.h2`
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 2.2rem;
`;

const Projects: React.FC = () => {
    return (
        <ProjectsWrapper>
            <Title>Projects</Title>
            <Stub>I have other projects in private VCS repositories [Gitlab, Github, Bitbucket, Gogs]. These are a few public projects I worked on. They're included purely on the basis that they were fun to build :)</Stub>
            <ProjectContainer>
            {projects.map((project: Project) => {
                return (
                    <ProjectItem>
                        <To href={project.link} target="_blank">
                            <ProjectImage src={project.image}/>
                            <h3>{project.title}</h3>
                        </To>
                    </ProjectItem>
                )
            })}
            </ProjectContainer>
        </ProjectsWrapper>
    );
}

export default Projects;