import * as React from 'react';
import styled from 'styled-components';
import {pageLoadAnimation} from '../../core/animations';
import AboutMe from '../generic/AboutMe';

const AboutWrapper = styled.div`
    ${pageLoadAnimation}
    width: 100%;
`;

const About: React.FC = () => {
    return (
        <AboutWrapper>
            <AboutMe/>
        </AboutWrapper>
    );
}

export default About;