import * as React from 'react';
import styled from 'styled-components';

import colors from '../../core/colors';
import links from '../../core/links';

import GithubIcon from '../../assets/GithubIcon';
import GitlabIcon from '../../assets/GitlabIcon';
import GlobeIcon from '../../assets/GlobeIcon';
import IconButton from '../generic/IconButton';

const FooterWrapper = styled.footer`
    min-height: 4rem;
    background-color: ${colors.black};
    margin: 0 auto;
    width: 100%;
`;

const FlexContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 2rem;
    height: 4rem;

    & > * {
        padding: 0 1rem;
    }
`;

const navigateTo = function (url: string) {
    window.open(url, '_blank');
}

const iconLinks = [
    { icon: <GithubIcon/>, click: links.github },
    { icon: <GitlabIcon/>, click: links.gitlab },
    { icon: <GlobeIcon/>, click: links.site },
]

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <FlexContainer>
                {iconLinks.map((il) => {
                    return <IconButton icon={il.icon} onClick={() => navigateTo(il.click)}/>
                })}
            </FlexContainer>
        </FooterWrapper>
    );
}

export default Footer;