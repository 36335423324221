import React from 'react';
import styled from 'styled-components';

import Card from '../../../components/generic/Card';
import { BlogContent, BlogText, Spacer, P, SongBlock } from '../../../components/blog/utils';

const Artifact = () => {
    return (
        <Card>
            <BlogContent>
                <h2>Artifact</h2>
                <Spacer/>
                <P>"Something observed in a scientific investigation or experiment that is not naturally present but occurs as a result of the preparative or investigative procedure."</P>
                <Spacer/>
                <P>
                    The debilitating headaches started at 10 years old. 
                    A subsequent Cat Scan and MRI determined that I had an abnormal growth located in my brain stem. Specifically in the Fourth ventricle near the Medulla oblongata. 
                    The Fourth ventricle provides a cushioning effect and is part of the canal that delivers cerebrospinal fluid from the brain to the spine.
                    The Medulla contains the cardiac, respiratory, vomiting and vasomotor centers, and therefore deals with the autonomic functions of breathing, heart rate and blood pressure as well as the sleep wake cycle. [Are you breathing manually right now? How about now?].
                </P>
                <Spacer/>
                <P> 
                    The tumour was not able to be removed due to cells being intermingled and the potential loss of function in surrounding healthy tissue [paralysis, comatose, loss of autonomy].
                    It was determined that I would have a biopsy and a miniscule portion of the tumour would be resected.
                    During the 10 hour surgery the back of my skull and brain were bisected open. The surgeons and team took a journey to the center of my head and started to cut a piece out. 
                    Upon starting the cut my heart rate dropped significantly enough to warrant a reassessment. My life was in others hands. The cut was made even smaller, a sample excised, and I was closed up.
                    Afterwards I felt like an empty husk, a mere physical structure. The morphine helped, but I have never felt the inside of my head like that before; simply indescribable. I was unable to walk, balance, or coordinate movements. At least the brain surgery was over.
                </P>
                <Spacer/>
                <P>
                    Recovering further, a diagnoses of a Pilocytic astrocytoma [grade 1] was made. 
                    A 70 week chemotherapy plan was presented and I had a portacath installed in my chest. 
                    Every week I would visit and have an injection of Vinblastine, which blocks cell division. I lost all hair and was generally weak. 
                    The worst part was the neuropathic pain and nausea. A true period of decay in my life. 
                    Upon completion there was no change in the tumours condition. It is "stable".
                    Returning to normal life took around a year. Most of the side effects have all but vanished. Seldomly after standing up I will get an episode wherein, I receive a blood rush to the head, have weakness in legs, and brain fog. 
                    Although a hindrance. It's a warm feeling and takes me back to where I once was.
                    There are no future or past genetic impacts. I was fortunate enough to have a random mutation.  
                </P>
                <Spacer/>
                <P>
                    As time passes, memories fade. 
                    I had many close friends that I met during my treatment pass away.
                    It generates questions that induce survivor guilt. Why me? Am I on borrowed time? What control does one have in a situation like this? 
                    It's made me come to terms that I've never had control. Nobody does. Its incredibly loose and delayed if it exists. Everything is deterministic and I'm just along for the ride.
                </P>
                <Spacer/>
                <P>
                    The artifact isn't just the cancer, but the near death experience it brought. It's still here, an unscratchable itch in the back of my head both mentally and physically. 
                    I will be forever humble. 
                </P>
                <Spacer/>
                <P>
                    This moment I could die happy. Yet for now, I'm alive.
                </P>
            </BlogContent>
        </Card>
    );
}

export default Artifact;