import Handel from '../../../assets/images/music/other/george_frideric_handel.jpg';
import Bach from '../../../assets/images/music/other/bach.jpg';
import Paco from '../../../assets/images/music/other/paco_de_lucia.jpg';

import PogoWonderland from '../../../assets/images/music/pogo/pogo_wonderland.jpg';
import PogoFluctuate from '../../../assets/images/music/pogo/pogo_fluctuate.jpg';
import PogoSpiritedAway from '../../../assets/images/music/pogo/pogo_spirited_away.jpg';
import PogoLetTheRightOneIn from '../../../assets/images/music/pogo/pogo_blood_kiss.jpg'

import NmeshNuWav from '../../../assets/images/music/nmesh/nmeshnuwave.jpg';
import NmeshLostPathToEden from '../../../assets/images/music/nmesh/nmeshpathtolosteden.jpg';
import NmeshDreamSequins from '../../../assets/images/music/nmesh/nmeshdreamsequins.jpg';

import PrismCorpVirtualClearSkies from '../../../assets/images/music/prismcorp/prismcorpvirtualclearskies.jpg'
import PrismCorpVirtualHome from '../../../assets/images/music/prismcorp/prismcorphome.jpg'

import NewDreamsAvalon from '../../../assets/images/music/newdreams/newdreamsavalon.jpg'
import NewDreamsFuji from '../../../assets/images/music/newdreams/newdreamsfuji.jpg'

import LaserDiscNewDreams from '../../../assets/images/music/laserdisc/laserdiscnewdreams.jpg'

import MacintoshPlusFloralShoppe from '../../../assets/images/music/macintoshplus/macintoshplusfloralshoppe.jpg'

import OpnGoodTime from '../../../assets/images/music/opn/opngoodtime.jpeg'
import OpnPactBetween from '../../../assets/images/music/opn/opnpactbetweenstrangers.jpeg'
import OpnReturnal from '../../../assets/images/music/opn/opnreturnal.jpg'

import BocMusicHasTheRight from '../../../assets/images/music/boc/bocmusichastheright.jpg'
import BocGeogaddi from '../../../assets/images/music/boc/bocgeogaddi.jpg'
import BocCampireHeadPhase from '../../../assets/images/music/boc/boccampfireheadphase.jpg'
import BocTomorrowsHarvest from '../../../assets/images/music/boc/boctomorrowsharvest.png'
import BocTwoism from '../../../assets/images/music/boc/boctwoism.jpg'
import BocAFewOldTunes from '../../../assets/images/music/boc/bocafewoldtunes.jpg'

import DaftPunkDiscovery from '../../../assets/images/music/daftpunk/daftpunkdiscovery.jpg'
import DaftPunkTronLegacy from '../../../assets/images/music/daftpunk/daftpunktronlegacy.jpg'

import InternetClubVanishingVision from '../../../assets/images/music/internetclub/internetclub_vanishing_vision.jpg'
import InternetClubWebinar from '../../../assets/images/music/internetclub/internet_club_webinar.jpg'
import InternetClubNMC from '../../../assets/images/music/internetclub/internet_club_new_mill_concepts.jpg'


class Artist {
    name: string;
    albums: Album[];
    
    constructor(name: string, albums: Album[]) {
        this.name = name;
        this.albums = albums;
    }
}

class Song {
    title: string;
    links: string[];
    image: any; 

    constructor(
        title: string,
        links?: string[],
        image?: any        
    ) {
        this.title = title;
        this.links = links;
        this.image = image;
    }
}

class Album {
    name: string;
    songs: Song[];
    image: any;

    constructor(
        name: string,
        songs: Song[],
        image?: any
    ) {
        this.name = name;
        this.songs = songs;
        this.image = image;
    }
}


// To add

//https://deathsdynamicshroud.bandcamp.com/track/--38
//https://www.youtube.com/watch?v=_W4PJUOeVYw&t=422s
//https://www.youtube.com/watch?v=eUi8BLm1bFo
//https://www.youtube.com/watch?v=y_goHl-GuNk
//https://www.youtube.com/watch?v=Nz7k0DWSGL012
//https://www.youtube.com/watch?v=2oyhlad64-s
//https://www.youtube.com/watch?v=uoiXvQhWrKY


const topPicks = new Artist(
    `Pleasurable Present Picks`,
    [
        new Album(
            ``,
            [
                new Song(
                    `Nmesh - 心はシダであります`,
                    [
                        "https://nmesh.bandcamp.com/track/nmesh-6"
                    ],
                    NmeshLostPathToEden
                ),
                new Song(
                    `Bach - Passacaglia & Fugue In C Minor - BWV 582`,
                    [
                        "https://www.youtube.com/watch?v=_W4PJUOeVYw"
                    ],
                    Bach
                ),
                new Song(
                    `ClearSkies: Complete Edition - Dokdo Island Bridge`,
                    [
                        "https://vektroid.bandcamp.com/track/dokdo-island-bridge"
                    ],
                    PrismCorpVirtualClearSkies
                ),
                new Song(
                    `Boards Of Canada - 5.9.78`,
                    [
                        "https://www.youtube.com/watch?v=3A786lOX8jc"
                    ],
                    BocAFewOldTunes
                ),
                new Song(
                    `Paco de Lucia - Entre dos Aguas`,
                    [
                        "https://www.youtube.com/watch?v=2oyhlad64-s"
                    ],
                    Paco

                ),
                new Song(
                    `Oneohtrix Point Never - Where Does Time Go`,
                    [
                        "https://opnemego.bandcamp.com/track/where-does-time-go"
                    ],
                    OpnReturnal
                ),
                new Song(
                    `Handel Georg Friedrich - 6 Organ Concertos , Op. 4`,
                    [
                        "https://www.youtube.com/watch?v=uoiXvQhWrKY"
                    ],
                    Handel
                ),
                new Song(
                    `Boards of Canada - Roygbiv`,
                    [
                        "https://www.youtube.com/watch?v=yT0gRc2c2wQ"
                    ],
                    BocMusicHasTheRight
                ),
            ],
            null
            )
    ],
)

const singleString = "Singles"

const artistList: Artist[] = []

artistList.push(topPicks);

const pogo = new Artist(
    "Pogo",
    [
        new Album(
            singleString,
            [
                new Song(
                    "Bigger Blue (Spirited Away Remix)",
                    [
                        "https://www.youtube.com/watch?v=GAoPLTFE5zw"
                    ],
                    PogoSpiritedAway
                ),
                new Song(
                    "Counterpoint",
                    [
                        "https://www.youtube.com/watch?v=wwXMCh7DIlA"
                    ],
                    null
                ),
                new Song(
                    `Blood Kiss (Let the Right One In Remix)`,
                    [
                        "https://www.youtube.com/watch?v=X_aUPp3E3Co"
                    ],
                    PogoLetTheRightOneIn
                )
            ],
            null
        ),
        new Album(
            "Fluctuate",
            [
                new Song(
                    "Skyrim Symphony",
                    [
                        "https://www.youtube.com/watch?v=MvwyaEanR7s",
                        "https://pogomix.bandcamp.com/track/skyrim-symphony",
                    ],
                    null
                ),
            ],
            PogoFluctuate,
        ),
        new Album(
            "Wonderland",
            [
                new Song(
                    "Lost",
                    [
                        "https://www.youtube.com/watch?v=O1zXi9HmTGE",
                        "https://pogomix.bandcamp.com/track/lost"
                    ],
                    null,
                ),
            ],
            PogoWonderland
        )
    ]
);

artistList.push(pogo);

const nmesh = new Artist(
    "Nmesh",
    [
        new Album(
            "Nu​.​wav Hallucinations",
            [   
                new Song(
                    `Arctic Cat®: Taking Charge!`,
                    [
                        "https://nmesh.bandcamp.com/track/arctic-cat-r-taking-charge"
                    ],
                    null,
                ),
                new Song(
                    "Even Though I Tried (I Can’t Break Free)",
                    [
                        "https://nmesh.bandcamp.com/track/even-though-i-tried-i-cant-break-free"
                    ],
                    null,
                ),
                new Song(
                    `I Got Your Letter`,
                    [
                        "https://nmesh.bandcamp.com/track/i-got-your-letter"
                    ],
                    null,
                ),
            ],
            NmeshNuWav
        ),
        new Album(
            "ロストエデンへのパス",
            [
                new Song(
                    `心はシダであります`,
                    [
                        "https://nmesh.bandcamp.com/track/nmesh-6"
                    ],
                    null
                ),
                new Song(
                    `黒い水の上に反射`,
                    [
                        "https://nmesh.bandcamp.com/track/nmesh-5"
                    ],
                    null,
                ),

            ],
            NmeshLostPathToEden
        ),
        new Album(
            `Dream Sequins®`,
            [
                new Song(
                    `KΞΞP​/​/​/​/​/​THIS​/​/​/​/​/`,
                    [
                        "https://nmesh.bandcamp.com/track/k-p-this"
                    ],
                    null
                ),
                new Song(
                    `
                    Climbing The Corporate Ladder`,
                    [
                        "https://nmesh.bandcamp.com/track/climbing-the-corporate-ladder"
                    ],
                    null
                ),
                new Song(
                    `If I Tell You That I’m Happy`,
                    [
                        "https://nmesh.bandcamp.com/track/if-i-tell-you-that-i-m-happy"
                    ],
                    null
                ),
            ],
            NmeshDreamSequins
        )
    ]
);

artistList.push(nmesh);

const prismcorp = new Artist(
    "PrismCorp Virtual Enterprises",
    [
        new Album(
            `ClearSkies: Complete Edition`,
            [
                new Song(
                    `Dokdo Island Bridge`,
                    [
                        "https://vektroid.bandcamp.com/track/dokdo-island-bridge"
                    ],
                    null
                ),
                new Song(
                    `Iridani`,
                    [
                        "https://vektroid.bandcamp.com/track/iridani"
                    ],
                    null
                ),
                new Song(
                    `Narayani Falls`,
                    [
                        "https://vektroid.bandcamp.com/track/narayani-falls"
                    ],
                    null
                ),
            ],
            PrismCorpVirtualClearSkies
        ),
        new Album(
            `Home: Complete Edition`,
            [
                new Song(
                    `Seasons`,
                    [
                        "https://vektroid.bandcamp.com/track/seasons"
                    ],
                    null
                ),
            ],
            PrismCorpVirtualHome
        )
    ]
);

artistList.push(prismcorp);

const newdreamsltd = new Artist(
    `New Dreams Ltd.`,
    [
        new Album(
            `Initiation Tape: Isle of Avalon Edition`,
            [
                new Song(
                    `Cold Water`,
                    [
                        "https://vektroid.bandcamp.com/track/cold-water"
                    ],
                    null
                )
            ],
            NewDreamsAvalon
        ),
        new Album(
            `Fuji Grid TV EX`,
            [
                new Song(
                    `Ur Girl`,
                    [
                        "https://vektroid.bandcamp.com/track/ur-girl"
                    ],
                    null
                )
            ],
            NewDreamsFuji
        )
    ],
);

artistList.push(newdreamsltd);

const laserdiscvision = new Artist(
    `Laserdisc Visions`,
    [
        new Album(
            `New Dreams Ltd.`,
            [
                new Song(
                    `Data Dream`,
                    [
                        "https://vektroid.bandcamp.com/track/data-dream"
                    ],
                    null
                )
            ],
            LaserDiscNewDreams
        )
    ]
)

artistList.push(laserdiscvision);

const macintoshplus = new Artist(
    `Macintosh Plus`,
    [
        new Album(
          `Floral Shoppe`,
          [
              new Song(
                  `ECCOと悪寒ダイビング`,
                  [
                    "https://vektroid.bandcamp.com/track/ecco"
                  ],
                  null
              )
          ],
          MacintoshPlusFloralShoppe
        )
    ]
)

artistList.push(macintoshplus);

const opn = new Artist(
    `Oneohtrix Point Never`,
    [
        new Album(
            `Good Time Original Motion Picture Soundtrack`,
            [
                new Song(
                    `The Pure and the Damned`,
                    [
                        "https://www.youtube.com/watch?v=TzZ6-OqjoIg"
                    ],
                    null
                ),
                new Song(
                    `Hospital Escape / Access-A-Ride`,
                    [
                        "https://www.youtube.com/watch?v=6EWxl2u4L2E"
                    ],
                    null
                )
            ],
            OpnGoodTime
        ),
        new Album(
            `A Pact Between Strangers`,
            [
                new Song(
                    `A Pact Between Strangers`,
                    [
                        "https://www.youtube.com/watch?v=rJkMzC3unqc"
                    ],
                    null
                )
            ],
            OpnPactBetween
        ),
        new Album(
            `Returnal`,
            [
                new Song(
                    `Where Does Time Go`,
                    [
                        "https://opnemego.bandcamp.com/track/where-does-time-go"
                    ],
                    null
                )
            ],
            OpnReturnal
        )
    ]
)

artistList.push(opn);

const boardsofcanada = new Artist(
    `Boards of Canada`, 
    [
        new Album(
            `Music Has the Right to Children`,
            [
                new Song(
                    `Roygbiv`,
                    [
                        "https://www.youtube.com/watch?v=yT0gRc2c2wQ"
                    ],
                    null
                ),
                new Song(
                    `Aquarius`,
                    [
                        "https://www.youtube.com/watch?v=rRcr5hYCxh0"
                    ],
                    null
                ),
                new Song(
                    `Rue the Whirl`,
                    [
                        "https://www.youtube.com/watch?v=8Kban1IOQ4M"
                    ],
                    null
                )
            ],
            BocMusicHasTheRight
        ),
        new Album(
            `Geogaddi`,
            [
                new Song(
                    `Julie and Candy`,
                    [
                        "https://www.youtube.com/watch?v=VF-ToDzj0ig"
                    ],
                    null
                ),
                new Song(
                    `Music Is Math`,
                    [
                        "https://www.youtube.com/watch?v=lhPWJHrwgR4"
                    ],
                    null
                ),
            ],
            BocGeogaddi   
        ),
        new Album(
            `The Campfire Headphase`,
            [
                new Song(
                    `Chromakey Dreamcoat`,
                    [
                        "https://www.youtube.com/watch?v=ex6-A803EnE"
                    ],
                    null
                ),
                new Song(
                    `Oscar See Through Red Eye`,
                    [
                        "https://www.youtube.com/watch?v=Gwx-sl-4JXs"
                    ],
                    null
                )
            ],
            BocCampireHeadPhase   
        ),
        new Album(
            `Tomorrow's Harvest`,
            [
                new Song(
                    `Jacquard Causeway`,
                    [
                        "https://www.youtube.com/watch?v=7olRuCKhCfA"
                    ],
                    null
                ),
                new Song(
                    `Come to Dust`,
                    [
                        "https://www.youtube.com/watch?v=cKmqf_eBoFI"
                    ],
                    null
                ),
                new Song(
                    `Sick Times`,
                    [
                        "https://www.youtube.com/watch?v=yZQluCiQWUk"
                    ],
                    null
                ),
                new Song(
                    `Palace Posy`,
                    [
                        "https://www.youtube.com/watch?v=ov0FAPhRK0c"
                    ],
                    null
                ),
                new Song(
                    `Nothing is Real`,
                    [
                        "https://www.youtube.com/watch?v=ftqkP3n2qLU"
                    ],
                    null
                ),
                new Song(
                    `New Seeds`,
                    [
                        "https://www.youtube.com/watch?v=i3IWUz7JlRk"
                    ],
                    null
                ),
            ],
            BocTomorrowsHarvest
        ),
        new Album(
            `Twoism`,
            [
                new Song(
                    `Oirectine`,
                    [
                        "https://www.youtube.com/watch?v=bQ7_RqMWgXY"
                    ],
                    null
                ),
                new Song(
                    `Iced Cooly`,
                    [
                        "https://www.youtube.com/watch?v=_6g9qYShpyc"
                    ],
                    null
                ),
                new Song(
                    `Twoism`,
                    [
                        "https://www.youtube.com/watch?v=1AnO8CpaNEs"
                    ],
                    null
                )
            ],
            BocTwoism
        ),
        new Album(
            `Old Tunes Vol 1 + 2`,
            [
                new Song(
                    `Trapped`,
                    [
                        "https://www.youtube.com/watch?v=ixoH1vuAXXc"
                    ],
                    null
                ),
                new Song(
                    `She is P`,
                    [
                        "https://www.youtube.com/watch?v=Lt2Wvg7U-D8"
                    ],
                    null
                ),
                new Song(
                    `5.9.78`,
                    [
                        "https://www.youtube.com/watch?v=3A786lOX8jc"
                    ],
                    null
                ),
                new Song(
                    `Buckie High`,
                    [
                        "https://www.youtube.com/watch?v=v51mF-p5Agk"
                    ],
                    null
                ),
            ],
            BocAFewOldTunes
        )
    ]
)

artistList.push(boardsofcanada);

const daftpunk = new Artist(
    `Daft Punk`,
    [
        new Album(
            `Discovery`,
            [
                new Song(
                    `Voyager`,
                    [
                        "https://www.youtube.com/watch?v=INbgG9M0WYE"
                    ],
                    null
                ),
                new Song(
                    `Something About Us`,
                    [
                        "https://www.youtube.com/watch?v=em0MknB6wFo"
                    ],
                    null
                ),
                new Song(
                    `Aerodynamic`,
                    [
                        "https://www.youtube.com/watch?v=Mjpu0-o9iek"
                    ],
                    null
                ),
            ],
            DaftPunkDiscovery
        ),
        new Album(
            `Tron: Legacy`,
            [
                new Song(
                    `End of Line`,
                    [
                        "https://www.youtube.com/watch?v=NOMa56y_Was"
                    ],
                    null
                ),
                new Song(
                    `TRON Legacy (End Titles)`,
                    [
                        "https://www.youtube.com/watch?v=G6tuvKE6_lA"
                    ],
                    null
                ),
            ],
            DaftPunkTronLegacy
        ),
    ]
)

artistList.push(daftpunk);

const internetClub = new Artist(
    `INTERNET CLUB`,
    [
        new Album(
            `VANISHING VISION`,
            [
                new Song(
                    `VELOCITY`,
                    [
                        "https://internetclub.bandcamp.com/track/velocity"
                    ],
                    null
                ),
                new Song(
                    `RENDERS`,
                    [
                        "https://internetclub.bandcamp.com/track/renders"
                    ],
                    null
                ),
            ],
            InternetClubVanishingVision
        ),
        new Album(
            `WEBINAR`,
            [
                new Song(
                    `DIGITAL TEAM BUILDING (SESSION 2)`,
                    [
                        "https://internetclub.bandcamp.com/track/digital-team-building-session-2"
                    ],
                    null
                ),
                new Song(
                    `OPTIMIZATION`,
                    [
                        "https://internetclub.bandcamp.com/track/optimization"
                    ],
                    null
                ),
            ],
            InternetClubWebinar
        ),
        new Album(
            `NEW MILLENIUM CONCEPTS`,
            [
                new Song(
                    `RAINING ZONE I`,
                    [
                        "https://internetclub.bandcamp.com/track/raining-zone-i"
                    ],
                    null
                ),
                new Song(
                    `WAKE SLEEP II`,
                    [
                        "https://internetclub.bandcamp.com/track/wake-sleep-ii"
                    ],
                    null
                ),
                new Song(
                    `PURE NIGHT`,
                    [
                        "https://internetclub.bandcamp.com/track/pure-night"
                    ],
                    null
                ),
            ],
            InternetClubNMC
        ),
    ]
)

artistList.push(internetClub);

export {artistList, Album, Song, Artist}