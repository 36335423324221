import React, { useState, useEffect } from 'react';
import {useRouteMatch, useParams} from 'react-router-dom'
import styled from 'styled-components';

import {Book, books, MediaType} from '../../content/books/books';
import { MarkdownRenderer } from '../renderers/MarkdownRenderer';
import { PDFRenderer } from '../renderers/PDFRenderer';

const BookWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const BookView: React.FC = () => {
    

    let { title } = useParams();
    console.log('Key');
    console.log(title);
    let book: Book = books[title];
    
    const content = book.content;
    return (
        <BookWrapper>
            <h1>{`${book.title} - ${book.author}`}</h1>
            
            {book.type === MediaType.MARKDOWN ? <MarkdownRenderer content={content}/> : null}
            {book.type === MediaType.PDF ? <PDFRenderer content={content}/> : null}
            
        </BookWrapper>
    );
}

export default BookView;