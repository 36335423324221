import { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const pageLoadAnimation = css`
    animation: ${fadeIn} ease-out 1.5s;
`;


const slideIn = keyframes`
  100% { 
    transform: translateX(0%); 
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0%); 
  }
  100% {
    transform: translateX(-100%); 
  }
`;

const rotate3dForward = keyframes`
  from { 
    transform: rotateY(0deg); 
  }
  to { 
    transform: rotateY(-360deg); 
  }
`;

const rotate3dBackward = keyframes`
  from { 
    transform: rotateY(-360deg); 
  }
  to { 
    transform: rotateY(0deg); 
  }
`;

const horizontalScroll = keyframes`
  0% { 
    transform: translateX(100%); 		
  }
  100% { 
    transform: translateX(-100%); 
  }
`

const slideInAnimation = css`
    animation: ${slideIn} 0.5s forwards;
`;

const slideOutAnimation = css`
    animation: ${slideOut} 0.5s forwards;
`;

const rotate3dAnimationForward = css`
    animation: ${rotate3dForward} 6s infinite linear;
`;


const rotate3dAnimationBackward = css`
    animation: ${rotate3dBackward} 3s infinite linear;
`;

const horizontalScrollAnimationLeftToRight = css`
  animation: ${horizontalScroll} ${({ time }) => `${time}s` || `15s`} linear infinite;
`

export { 
  pageLoadAnimation,
  slideInAnimation,
  slideOutAnimation,
  rotate3dAnimationForward,
  rotate3dAnimationBackward,
  horizontalScrollAnimationLeftToRight
};
