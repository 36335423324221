import React, { useState } from 'react';
import styled from 'styled-components';
import Terminal from '../Terminal/Terminal';

import {slideInAnimation, slideOutAnimation} from '../../core/animations';

//opacity
const TerminalWrapper = styled.div`
    position: absolute;
    left: 0px;
    top: 15vh;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    z-index: 10;
    
`;

const KeyboardStyle = styled.div`
    font-size: 2rem;
    font-style: monospace;
    background-color: grey;
    color: white;
    transition: transform .2s ease-in-out;
    user-select: none;
    border-radius:0.12em;
    box-sizing: border-box;

    &:hover {
        transform: scale(1.1);
    }
`;

const TerminalAnimation = styled.div`
    position: absolute;
    opacity: 0.7;
    transform: translateX(-100%);
    width: 100vw;
    height: 20rem;
    visibility: ${props => props.init ? 'visible' : 'hidden'};
    ${props => props.open ? slideInAnimation : slideOutAnimation}
`;

const TerminalBar: React.FC = () => {
    const [open,setOpen] = useState(false);
    const [init,setInit] = useState(false);

    const onClick = () => {
        setInit(true);
        setOpen(!open);
    }

    return (
        <TerminalWrapper open={open}>
            <KeyboardStyle onClick={onClick}>&gt;_</KeyboardStyle>
            <TerminalAnimation open={open} init={init}><Terminal/></TerminalAnimation>
        </TerminalWrapper>
    );
}

export default TerminalBar;